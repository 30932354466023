import { http } from '../utils/http'
import { utils } from "./utils";
import { Notify } from "./notifications";
import { URLS } from "@/utils/constants";

export default {
    close() {
        this.getNoAuth(
            URLS.cancel + "?state=" + this.$route.params.sdkActionUid,
            true
        ).then((res) => {
            if (!res) return;
            if (res.errors) return (this.errors = res.errors);

            if (confirm("Leave screen?")) {
                close();
            }
        });
    },
    isBusy() {
        return this.operations > 0;
    },

    addOp() {
        this.errors = {}
        this.operations++;
    },

    removeOp() {
        if (this.operations > 0)
            this.operations--;
    },

    hasOps() {
        // utils.logger('ops: ', this.operations)
        return this.operations > 0
    },

    delete(url, bool = false) {
        this.addOp();
        return http.delete(url)
            .then(res => {
                utils.logger("get result", res);
                this.removeOp();
                return this.handleGetResponse(res, bool);
            });
    },

    get(url, bool = false) {
        this.addOp();
        return http.get(url)
            .then(res => {
                utils.logger("get result", res);
                this.removeOp();
                return this.handleGetResponse(res, bool);
            });
    },

    getNoAuth(url, bool = false) {
        this.addOp();
        return http.getNoAuth(url)
            .then(res => {
                utils.logger(res);
                this.removeOp();
                return this.handleGetResponse(res, bool);
            });
    },

    getNoAuthLoader(url, bool = false) {
        return http.getNoAuth(url)
            .then(res => {
                this.removeOp();
                return this.handleGetResponse(res, bool);
            });
    },

    handleGetResponse(res, bool) {
        if (!res) return this.setErrors({ err: "Something went wrong, connectivity maybe?" }, bool);
        return res.json()
            .then(r => {
                utils.logger("get response", r);
                if (res.status === 400) return r; // return object as is to be handled upstream
                if (res.status !== 200) return this.setErrors(r.errors, bool);
                return r;
            })
            .catch(e => {
                utils.logger(e);
                if (res.status === 401) {
                    window.location.href = "./login";
                    return;
                }
            });
    },

    put(url, payload, message, callback = null, bool = true) {
        this.addOp();
        // utils.logger(url, payload);
        return http.put(url, payload)
            .then(res => {
                utils.logger("action", res);
                this.removeOp();
                return this.handlePostResponse(res, bool, callback, message);
            });
    },

    post(url, payload, message, callback = null, bool = true) {
        this.addOp();
        // utils.logger(url, payload);
        return http.post(url, payload)
            .then(res => {
                utils.logger("action", res);
                this.removeOp();
                return this.handlePostResponse(res, bool, callback, message);
            });
    },

    postNoAuth(url, payload, message, callback, bool = true) {
        this.addOp();
        utils.logger(url, utils.copy(payload));
        return http.postNoAuth(url, payload)
            .then(res => {
                utils.logger(res);
                this.removeOp();
                return this.handlePostResponse(res, bool, callback, message);
            });
    },

    handlePostResponse(res, bool, callback, message) {
        // this.reset();
        if (!res) return this.setErrors({ err: "Something went wrong, connectivity maybe?" }, bool);
        return res.json().then(r => {
            utils.logger("post response", r);
            if (res.status === 400) return r; // return object as is to be handled upstream
            if (res.status !== 200) return this.setErrors(r.errors, bool);

            if (callback) callback();
            this.setSuccess(bool, message);

            return r;
        })
            .catch(e => {
                utils.logger(e);
                if (res.status === 401) {
                    window.location.href = "./login";
                    return;
                }
            });
    },

    setErrors(res, bool) {
        // utils.logger("exception", res, bool);
        if (bool) {
            for (let e in res) {
                utils.logger("exception", e, res[e]);
                Notify.fire(
                    res[e],
                    'error'
                )
            }
        }
        return false;
    },

    setSuccess(bool, message) {
        if (bool)
            Notify.fire(
                message,
                'success'
            )
    },

    buildUrl(url) {
        // utils.logger("build base", url);
        let base = url + "?";
        return this.build(base, this.state.urlParams);
    },

    buildUrlWithParams(url, params) {
        // utils.logger("build base", url);
        let base = url + "?";
        return this.build(base, params);
    },

    build(base, params) {
        let count = 0;
        for (let i in params) {
            // first iteration skips this
            if (count !== 0) base = base + "&";
            base = base + i + "=" + encodeURIComponent(params[i]);
            count++;
        }
        return base;
    },

    /**
     * @param params an object of key value pairs representing the params to be serialized
     * ie, {sort: 'id,desc'} will be serialized to sort=id,desc
     * @returns {Promise<null>}
     */
    async addUrlParams(params) {
        let urlParams = this.state.urlParams;
        for (let p in params) urlParams[p] = params[p];
        return new Promise((resolve) => {
            this.setState({ urlParams: urlParams }, () => {
                resolve();
            });
        });
    }
}
