import Swal from "sweetalert2";

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 6000,
    timerProgressBar: true,
});

export class Notify {
    static fire(body, type){
        Toast.fire({
            title: body,
            icon: type
        })
    }

    static emptyNotif(){
        return {title: "", message: "", type: ""}
    }
}