<template>
  <div>
    <Navigation>
      <div class="mr-5">
        <a-page-header title="Manage Emails" class="pl-0" />
        <div v-if="hasOps()" class="text-center my-3">
          <a-spin size="large" />
        </div>
        <div class="my-3 row">
          <div class="col-6"></div>
          <div class="col-6 text-right">
            <a-button
              type="primary"
              :loading="hasOps()"
              @click="showModal('Add Email User')"
            >
              Add New User
            </a-button>
          </div>
        </div>
        <a-table :columns="columns" :data-source="data" rowKey="id">
          <span slot="name" slot-scope="text, record">
            {{ record.plan.product.prettyName }}
          </span>
          <span slot="plan" slot-scope="text, record">
            {{ record.plan.prettyName }}
          </span>
          <span slot="created" slot-scope="created">
            {{ formatDate(created) }}
          </span>
          <span slot="actions" slot-scope="text, record">
            <a-select default-value="quota" style="min-width: 175px">
              <a-select-option
                value="quota"
                @click="showActionModal(record.name, 'Change Quota')"
              >
                Change Quota
              </a-select-option>
              <a-select-option
                value="password"
                @click="showActionModal(record.name, 'Update password')"
              >
                Update password
              </a-select-option>
              <a-select-option value="delete" @click="showDeleteModal(record)">
                Delete
              </a-select-option>
            </a-select>
          </span>
        </a-table>
      </div>
    </Navigation>

    <a-modal
      :title="modalOperation"
      :visible="visible"
      :confirm-loading="hasOps()"
      @ok="createUser"
      @cancel="visible = false"
    >
      <span v-if="modalOperation === 'Add Email User'">
        User <small>(leave out the @domain.tld)</small>
        <a-form-item
          :has-feedback="errors.user ? true : false"
          :validate-status="errors.user ? 'error' : ''"
          :help="errors.user ? errors.user : ''"
        >
          <a-input
            allow-clear
            size="large"
            v-model="model.user"
            placeholder="Username"
            required="true"
          />
        </a-form-item>
        Full name
        <a-form-item
          :has-feedback="errors.real ? true : false"
          :validate-status="errors.real ? 'error' : ''"
          :help="errors.real ? errors.real : ''"
        >
          <a-input
            allow-clear
            size="large"
            v-model="model.real"
            placeholder="Display Name"
          />
        </a-form-item>
      </span>
      <span
        v-if="
          modalOperation === 'Add Email User' ||
          modalOperation === 'Update password'
        "
      >
        Password
        <a-form-item
          :has-feedback="errors.pass ? true : false"
          :validate-status="errors.pass ? 'error' : ''"
          :help="errors.pass ? errors.pass : ''"
        >
          <a-input
            type="password"
            allow-clear
            size="large"
            v-model="model.pass"
            placeholder="Password"
          />
        </a-form-item>
      </span>
      <span v-if="modalOperation === 'Change Quota'">
        New Quota (MBs)
        <a-form-item
          :has-feedback="errors.quota ? true : false"
          :validate-status="errors.quota ? 'error' : ''"
          :help="errors.quota ? errors.quota : ''"
        >
          <a-input
            type="number"
            allow-clear
            size="large"
            v-model="model.quota"
            placeholder="Quota"
          />
        </a-form-item>
      </span>
      <div>
        <small v-if="errors.user" class="text-danger">{{ errors.user }}</small>
        <small v-if="errors.integrityViolation" class="text-danger">{{
          errors.integrityViolation
        }}</small>
        <small v-if="errors.messageNotReadable" class="text-danger">{{
          errors.messageNotReadable
        }}</small>
        <small v-if="errors.generalException" class="text-danger">{{
          errors.generalException
        }}</small>
        <small v-if="errors.apiException" class="text-danger">{{
          errors.apiException
        }}</small>
      </div>
    </a-modal>
    <a-modal
      title="Delete User"
      :visible="deleteModalVisible"
      :confirm-loading="hasOps()"
      @ok="deleteUser"
      @cancel="deleteModalVisible = false"
    >
      <p>
        Are you sure you want to delete
        <strong>{{ deleteCandidate.values.email_address[0] }}</strong
        >?
      </p>
      <div>
        <small v-if="errors.user" class="text-danger">{{ errors.user }}</small>
        <small v-if="errors.integrityViolation" class="text-danger">{{
          errors.integrityViolation
        }}</small>
        <small v-if="errors.messageNotReadable" class="text-danger">{{
          errors.messageNotReadable
        }}</small>
        <small v-if="errors.generalException" class="text-danger">{{
          errors.generalException
        }}</small>
        <small v-if="errors.apiException" class="text-danger">{{
          errors.apiException
        }}</small>
      </div>
    </a-modal>
  </div>
</template>


<script>
import Navigation from "@/components/Navigation";
import baseMethods from "../../../utils/baseMethods";
import { URLS } from "@/utils/constants";
import { Notify } from '../../../utils/notifications';

// import moment from "moment";
// import { utils } from '../../utils/utils';

const columns = [
  {
    title: "Address",
    dataIndex: "values.email_address[0]",
    key: "address",
  },
  {
    title: "Quota User",
    dataIndex: "values.home_quota_used[0]",
    key: "home_quota_used",
  },
  {
    title: "Max Quota",
    dataIndex: "values.home_quota[0]",
    key: "home_quota",
  },
  {
    title: "Action",
    key: "action",
    scopedSlots: { customRender: "actions" },
  },
];

export default {
  name: "ManageEmails",
  data() {
    return {
      columns,
      data: [],
      model: {
        program: "create-user",
      },
      modalOperation: "",
      operations: 0,
      errors: {},
      visible: false,
      subscriptionUid: "",
      deleteCandidate: { values: { email_address: [] } },
      deleteModalVisible: false,
    };
  },
  components: {
    Navigation,
  },
  mounted() {
    this.subscriptionUid = this.$route.params.uid;
    this.fetchUsers();
  },
  methods: {
    showModal(operation) {
      this.clearModelData();
      this.model.program = "create-user";
      this.modalOperation = operation;
      this.visible = true;
    },
    showActionModal(user, operation) {
      this.clearModelData();
      this.model.program = "modify-user";
      this.model.user = user;
      this.modalOperation = operation;
      this.visible = true;
    },
    showDeleteModal(record) {
      this.clearModelData();
      this.model.program = "delete-user";
      this.deleteCandidate = record;
      this.deleteModalVisible = true;
    },
    deleteUser() {
      this.clearModelData();
      this.model.user = this.deleteCandidate.name;
      this.model.program = "delete-user";
      this.doUserAction();
    },
    clearModelData(){
      this.model = {};
      this.errors = {};
    },
    createUser() {
      this.doUserAction();
    },
    doUserAction() {
      this.get(
        this.buildUrlWithParams(
          URLS.emails + "/change/" + this.subscriptionUid,
          this.model
        ),
        false
      ).then((res) => {
        if (!res) return;
        if (res.errors) return (this.errors = res.errors);

        if (res.body.status === "failure") {
          this.errors = { "user": res.body.error };
          return;
        }

        this.fetchUsers();

        this.visible = false;
        this.deleteModalVisible = false;
      });
    },
    fetchUsers() {
      this.get(URLS.emails + "/" + this.subscriptionUid, true).then((res) => {
        if (!res) return;
        if (res.errors) return (this.errors = res.errors);

        if (res.body.status === "failure") {
          Notify.fire(res.body.error, "error");
          return;
        }

        this.data = res.body.data;
      });
    },
    ...baseMethods,
  },
};
</script>

<style>
</style>