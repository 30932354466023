export class utils {

    static debug = true;

    static camelCaseToSentenceCase(text) {
        const result = text.replace(/([A-Z])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        return finalResult;
    }

    static buildModel(denseModel) {
        for (var p in denseModel) {
            denseModel[p] = denseModel[p].value;
        }
        return denseModel;
    }

    static serialize = (obj) => {
        var str = [];
        for (var p in obj)
            // if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        // }
        return str.join("&");
    }


    static matchPath(path) {
        // utils.logger("path", path, window.location.pathname);
        return window.location.pathname.match(path);
    }

    static filterByPredicate(list, predicate) {
        return list.filter(predicate);
    }

    /**
     * @param list array
     * @param filter lambda expression
     * @returns {*}
     */
    static findByPredicate(list, predicate) {
        let index = list.findIndex(predicate);
        if (index === -1) return null;
        return list[index];
    }

    static getMapSetting(setting) {
        // setting format: m:1,f:2,
        let settings = utils.get("settings") ? utils.get("settings") : [];
        let key = settings.findIndex(v => v.key === setting);
        if (key === -1) return {};
        let obj = {};
        let array = [];
        if (settings.length > 0) {
            array = settings[key].value.split(",");
            for (let i in array) {
                let pcs = array[i].split(":");
                obj[pcs[0]] = pcs[1];
            }
        }
        return obj;
    }

    static getArraySetting(setting) {
        // setting format: yes,no,maybe
        let settings = utils.get("settings") ? utils.get("settings") : [];
        let key = settings.findIndex(v => v.key === setting);
        let array = [];
        if (settings.length > 0) {
            array = settings[key].value.split(",");
        }
        return array;
    }

    static getStringSetting(setting) {
        let settings = utils.get("settings") ? utils.get("settings") : [];
        let key = settings.findIndex(v => v.key === setting);
        if (!settings[key]) return "";
        return settings[key].value;
    }

    static getNumberSetting(setting) {
        let settings = utils.get("settings") ? utils.get("settings") : [];
        let key = settings.findIndex(v => v.key === setting);
        if (key === -1) return null;
        return parseInt(settings[key].value);
    }

    static get(key) {
        // utils.logger(key);
        if (localStorage.getItem(key) && localStorage.getItem(key) !== "undefined") {
            return JSON.parse(localStorage.getItem(key));
        }
        return null;
    }

    static save(key, obj) {
        // utils.logger(key, obj);
        localStorage.setItem(key, JSON.stringify(obj));
    }

    static copy(obj) {
        return JSON.parse(JSON.stringify(obj));
    }

    static logger(...obj) {
        if (utils.debug) {
            console.log(...obj);
        }
    }

    static random(min, max) {
        return Math.floor((Math.random() * (max - min) + min));
    }
}
