<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import Vue from "vue";
import VueRouter from "vue-router";
import Antd from "ant-design-vue";
// import 'ant-design-vue/dist/antd.css';
import "./assets/less/custom.less";

import Login from "./pages/Login.vue";
import Register from "./pages/Register.vue";
import Home from "./pages/Home.vue";
import RequestReset from "./pages/RequestReset.vue";
import Users from "./pages/users/Users.vue";
import NewUser from "./pages/users/NewUser.vue";
import EditUser from "./pages/users/EditUser.vue";
import ViewUser from "./pages/users/ViewUser.vue";
import Subscriptions from "./pages/subscriptions/Subscriptions.vue";
import NewSubscription from "./pages/subscriptions/NewSubscription.vue";
import ViewSubscription from "./pages/subscriptions/ViewSubscription.vue";
import EditSubscription from "./pages/subscriptions/EditSubscription.vue";
import Invoices from "./pages/invoices/Invoices.vue";
import ViewInvoice from "./pages/invoices/ViewInvoice.vue";
import Payments from "./pages/payments/Payments.vue";
import ViewPayment from "./pages/payments/ViewPayment.vue";
import Environments from "./pages/environments/Environments.vue";
import ViewEnvironment from "./pages/environments/ViewEnvironment.vue";
import ManageEmails from "./pages/subscriptions/manage/ManageEmails.vue";

Vue.use(VueRouter);
Vue.use(Antd);

const router = new VueRouter({
  mode: "history",
  routes: [
    { path: "/subscriptions/manage/email/:uid", name: "ManageEmails", component: ManageEmails },
    { path: "/environments/view/:uid", name: "ViewEnvironment", component: ViewEnvironment },
    { path: "/environments", name: "Environments", component: Environments },
    { path: "/payments/view/:uid", name: "ViewPayment", component: ViewPayment },
    { path: "/payments", name: "Payments", component: Payments },
    { path: "/invoices/view/:uid", name: "ViewInvoice", component: ViewInvoice },
    { path: "/invoices", name: "Invoices", component: Invoices },
    { path: "/subscriptions/edit/:uid", name: "EditSubscription", component: EditSubscription },
    { path: "/subscriptions/view/:uid", name: "ViewSubscription", component: ViewSubscription },
    { path: "/subscriptions/new", name: "NewSubscription", component: NewSubscription },
    { path: "/subscriptions", name: "Subscriptions", component: Subscriptions },
    { path: "/users/view/:uid", name: "ViewUser", component: ViewUser },
    { path: "/users/edit/:uid", name: "EditUser", component: EditUser },
    { path: "/users/new", name: "NewUser", component: NewUser },
    { path: "/users", name: "Users", component: Users },
    { path: "/reset", name: "RequestReset", component: RequestReset },
    { path: "/home", name: "Home", component: Home },
    { path: "/register", name: "Register", component: Register },
    { path: "/login", name: "Login", component: Login },
    { path: "/", name: "Default", component: Login },
  ],
});

// if(utils.debug)
//     utils.logger(localStorage.getItem("access_token"))

// auth check
// router.beforeEach((to, from, next) => {
//     if (to.name !== 'Login' && localStorage.getItem("access_token") === null) {
//         next("/")
//     } else next()
// })

export default {
  name: "App",
  router: router,
};
</script>
<style>
.ant-form-explain {
  font-size: 12px;
}

.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background: #ebe6e9;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #ebe6e9;
}
</style>