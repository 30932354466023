<template>
  <Navigation>
    <a-page-header title="View Payment" />
    <div class="mt-1 mx-4">
      <div v-if="hasOps()" class="text-center my-3">
        <a-spin size="large" />
      </div>
      <div class="row">
        <div class="col-5">
          <h3>Payment Details</h3>
          <hr />
          <div :key="key" v-for="(value, key) in model" class="row mt-2">
            <div class="col-5" style="font-weight: bold">
              {{ changeCase(key) }}
            </div>
            <div class="col-7">
              {{ value }}
            </div>
          </div>
        </div>
        <div class="col-7">
          <h3>Payment Items</h3>
          <hr />
          <div class="row">
            <div class="col">Transaction Type</div>
            <div class="col">Effective Date</div>
            <div class="col">Amount</div>
          </div>
          <hr>
          <div :key="key" v-for="(value, key) in items" class="row mt-3 d-flex align-items-center">
            <div class="col" style="">
              {{value.transactionType}}
            </div>
            <div class="col">
              <div>{{value.effectiveDate}}</div>
            </div>
            <div class="col">
              {{value.amount}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </Navigation>
</template>

<script>
import Navigation from "@/components/Navigation";
import { URLS } from "@/utils/constants";
import baseMethods from "@/utils/baseMethods";
import { utils } from "../../utils/utils";

export default {
  name: "ViewPayment",
  data() {
    return {
      errors: {},
      operations: 0,
      model: {},
      items: [],
    };
  },
  components: {
    Navigation,
  },
  mounted() {
    let paymentUid = this.$route.params.uid;
    this.getPayment(paymentUid);
  },
  methods: {
    changeCase(text) {
      return utils.camelCaseToSentenceCase(text);
    },
    getPayment(paymentUid) {
      this.get(`${URLS.payments}/${paymentUid}`, true).then((res) => {
        if (!res) return;
        if (res.errors) return (this.errors = res.errors);

        this.items = res.body.transactions;

        let toBeDeleted = ["trackingIds", "auditLogs", "bundleKeys", "transactions"];
        for (let k in toBeDeleted) {
          delete res.body[toBeDeleted[k]];
        }

        this.model = res.body;
      });
    },
    ...baseMethods,
  },
};
</script>

<style>
</style>