import {utils} from "./utils";
import {Notify} from "./notifications";

export class http {
    static serverUrl = process.env.VUE_APP_SERVER_URL;

    static put(url, data){
        utils.logger(url, data);
        return fetch(`${this.serverUrl}${url}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("access_token"),
            },
            body: JSON.stringify(data)
        })
        // .then(res => res.json())
            .catch(e => {
                utils.logger(e);
                Notify.fire("There something wrong with your request", "error");
            })
    }

    static delete(url){
        utils.logger(url);
        return fetch(`${this.serverUrl}${url}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("access_token"),
            },
            // body: JSON.stringify(data)
        })
        // .then(res => res.json())
            .catch(e => {
                utils.logger(e)
                Notify.fire("There something wrong with your request", "error")
            })
    }

    static post(url, data){
        utils.logger(url, data);
        return fetch(`${this.serverUrl}${url}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("access_token"),
            },
            body: JSON.stringify(data),
        })
            // .then(res => res.json())
            .catch(e => {
                utils.logger(e)
                Notify.fire("There something wrong with your request", "error")
            })
    }

    static postNoAuth(url, data){
        utils.logger(url, data);
        return fetch(`${this.serverUrl}${url}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                // "Authorization": "Bearer "+localStorage.getItem("access_token"),
            },
            body: JSON.stringify(data),
        })
            // .then(res => res.json())
            .catch(e => {
                utils.logger(e)
                Notify.fire("There something wrong with your request", "error")
            })
    }

    static get(url){
        utils.logger(url);
        return fetch(`${this.serverUrl}${url}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("access_token"),
            },
            // body: JSON.stringify(data)
        })
            // .then(res => res.json())
            .catch(e => {
                utils.logger(e)
                Notify.fire("There something wrong with your request", "error")
            })
    }

    static getNoAuth(url){
        utils.logger(url);
        return fetch(`${this.serverUrl}${url}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                // "Authorization": "Bearer "+localStorage.getItem("access_token"),
            },
            // body: JSON.stringify(data)
        })
            // .then(res => res.json())
            .catch(e => {
                utils.logger(e)
                Notify.fire("There something wrong with your request", "error")
            })
    }

    // data should be type FormData
    static postFormDataNoAuth(url, data){
        utils.logger(url, data);

        let requestOptions = {
            method: 'POST',
            body: data,
        };

        return fetch(`${this.serverUrl}${url}`, requestOptions)
            // .then(response => response.json())
            .catch(e => {
                utils.logger(e)
                Notify.fire("There something wrong with your request", "error")
            })
    }
}
