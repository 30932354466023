<template>
  <Navigation>
    <a-page-header title="View User" />
    <div class="mt-1 ml-4">
      <div v-if="hasOps()" class="text-center my-3">
        <a-spin size="large" />
      </div>
      <div :key="key" v-for="(value, key) in model" class="row mt-2">
        <div class="col-2" style="font-weight: bold">
          {{ changeCase(key) }}
        </div>
        <div class="col-10">
          {{ value }}
        </div>
      </div>
    </div>
  </Navigation>
</template>

<script>
import Navigation from "@/components/Navigation";
import { URLS } from "@/utils/constants";
import baseMethods from "@/utils/baseMethods";
import { utils } from "../../utils/utils";

export default {
  name: "ViewUser",
  data() {
    return {
      errors: {},
      operations: 0,
      model: {},
    };
  },
  components: {
    Navigation,
  },
  mounted() {
    let userUid = this.$route.params.uid;
    this.getUser(userUid);
  },
  methods: {
    changeCase(text) {
      return utils.camelCaseToSentenceCase(text);
    },
    getUser(userUid) {
      this.get(`${URLS.users}/${userUid}`, true).then((res) => {
        if (!res) return;
        if (res.errors) return (this.errors = res.errors);

        res.body.userGroupsList = res.body.userGroups
          .map((group) => group.name)
          .join(", ");

        delete res.body.killBillUrl;
        delete res.body.userGroups;
        delete res.body.clearPassword;
        delete res.body.type;

        this.model = res.body;
      });
    },
    ...baseMethods,
  },
};
</script>

<style>
</style>