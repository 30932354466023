<template>
  <div>
    <Navigation>
      <a-page-header title="Environments" class="pl-0" />
      <div class="mr-3">
        <div v-if="hasOps()" class="text-center my-3">
          <a-spin size="large" />
        </div>
        <div class="my-3 row">
          <div class="col-6">
            <!-- <a-select
              default-value="delete"
              style="min-width: 80px"
              class="mr-5"
            >
              <a-select-option value="delete" @click="doBulkAction('delete')">
                Delete
              </a-select-option>
            </a-select> -->
            <a-input-search
              allow-clear
              placeholder="Search by Label, Domain"
              style="width: 400px"
              @search="onSearch"
            />
          </div>
          <div class="col-6 text-right"></div>
        </div>
        <a-table
          :columns="columns"
          :data-source="data"
          :pagination="pagination"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
          @change="handleTableChange"
          rowKey="id"
        >
          <span slot="created" slot-scope="created">
            {{ formatDate(created) }}
          </span>
          <span slot="actions" slot-scope="text, record">
            <a-select :default-value="(record.balance > 0) ? 'edit' : 'view'" style="min-width: 150px">
              <a-select-option value="view">
                <router-link :to="'/environments/view/' + record.uid">
                  View
                </router-link>
              </a-select-option>
              <a-select-option v-if="record.label != 'domain'" value="redeploy" @click="showModal(record, 'update')">
                  Update
              </a-select-option>
              <a-select-option value="disable" @click="showModal(record, 'remove')">
                  Disable
              </a-select-option>
              <a-select-option v-if="record.label != 'domain' && record.label != 'storage'" value="ssl" @click="showModal(record, 'ssl')">
                  Generate SSL
              </a-select-option>
            </a-select>
          </span>
        </a-table>
      </div>
    </Navigation>
    <a-modal
      title="Update environment"
      :visible="visible"
      :confirm-loading="hasOps()"
      @ok="update"
      @cancel="visible = false"
    >
      <p>
        Are you sure you want to update this environment?
      </p>
      <div>
        <small v-if="errors.integrityViolation" class="text-danger">{{
          errors.integrityViolation
        }}</small>
        <small v-if="errors.messageNotReadable" class="text-danger">{{
          errors.messageNotReadable
        }}</small>
        <small v-if="errors.generalException" class="text-danger">{{
          errors.generalException
        }}</small>
        <small v-if="errors.apiException" class="text-danger">{{
          errors.apiException
        }}</small>
      </div>
    </a-modal>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import baseMethods from "../../utils/baseMethods";
import { URLS } from "@/utils/constants";

import moment from "moment";
import { utils } from "../../utils/utils";

const columns = [
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Username",
    dataIndex: "username",
    key: "username",
  },
  {
    title: "Domain name",
    key: "domain",
    dataIndex: "domain",
  },
  {
    title: "Status",
    key: "active",
    dataIndex: "active",
  },
  {
    title: "Created",
    key: "created",
    dataIndex: "created",
    scopedSlots: { customRender: "created" },
  },
  {
    title: "Action",
    key: "action",
    scopedSlots: { customRender: "actions" },
  },
];

export default {
  name: "Environments",
  data() {
    return {
      data: [],
      columns,
      selectedRowKeys: [],
      updateAction: "update",
      pagination: {
        current: 1,
        pageSize: 10,
        total: 1,
      },
      params: {
        size: 10,
        page: 0,
        sort: "id,desc",
      },
      operations: 0,
      visible: false,
      updateCandidate: {},
      errors: {},
    };
  },
  created() {
    this.getEnvironments();
  },
  components: {
    Navigation,
  },
  methods: {
    update(){
      let action = this.updateAction;
      this.get(
        URLS.environments + "/"+ action +"/" + this.updateCandidate.uid,
        true
      ).then((res) => {
        if (!res) return;
        if (res.errors) return (this.errors = res.errors);

        this.visible = false;

        this.getEnvironments();
      });
    },
    onSearch(value) {
      console.log(value);
      let params = utils.copy(this.params);
      // params.userUid = value;
      params.label = value;
      params.domain = value;
      params.userUid = value;
      this.params = params;
      this.getEnvironments();
    },
    handleTableChange(pagination) {
      console.log(pagination);
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.params.size = pager.pageSize;
      this.params.page = pager.current - 1;
      this.getEnvironments();
    },
    async doBulkAction(action) {
      switch (action) {
        case "delete":
          for (let i in this.selectedRowKeys) {
            this.updateCandidate = this.data.find(
              (item) => item.id === this.selectedRowKeys[i]
            );
            await this.deleteEnvironment();
            this.updateCandidate = {};
          }
      }
    },
    showModal(record, action) {
      this.updateAction = action;
      this.updateCandidate = record;
      this.visible = true;
    },
    formatDate(dateString) {
      return moment(dateString).format("DD-MMM-YYYY HH:mm:ss");
    },
    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    getEnvironments() {
      this.get(this.buildUrlWithParams(URLS.environments, this.params), true).then(
        (res) => {
          if (!res) return;
          if (res.errors) return (this.errors = res.errors);

          this.data = res.body.content;
          this.pagination.total = res.body.totalElements;
        }
      );
    },
    ...baseMethods,
  },
};
</script>
<style>
</style>