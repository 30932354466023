<template>
  <Navigation>
    <a-page-header
      title="Create New Subscriptions"
      sub-title="Create Zimscape subscriptions"
    />
    <div class="row d-flex ml-3 align-items-center mt-1">
      <div class="col-8">
        <a-form
          id="components-form-demo-normal-login"
          class="login-form"
          @submit="handleSubmit"
        >
          <p class="text-center">
            Every deployment must be related to a domain name
          </p>

          <!-- User Select Only for admins -->
          <div v-if="isAdmin()" class="row d-flex mb-4">
            <div class="col-6">
              <a-select
                allowClear
                size="large"
                placeholder="Please choose a user"
                option-label-prop="label"
                @change="handleUserChange"
                v-model="selectedUser"
              >
                <a-select-option
                  v-for="user in users"
                  :key="user.id"
                  :value="user.uid"
                  :label="user.email"
                >
                  <div>{{ user.email }}</div>
                </a-select-option>
              </a-select>
            </div>
          </div>

          <!-- Product Select -->
          <div class="row d-flex mb-4">
            <div class="col-6">
              <a-select
                allowClear
                size="large"
                placeholder="Please choose a product"
                option-label-prop="label"
                @change="handleProductChange"
                v-model="product"
              >
                <a-select-option
                  v-for="productItem in products"
                  :key="productItem.id"
                  :value="productItem.name"
                  :label="productItem.prettyName"
                >
                  <strong>{{ productItem.prettyName }}</strong>
                  <div>{{ productItem.description }}</div>
                </a-select-option>
              </a-select>
            </div>
          </div>

          <!-- Billing Period -->
          <div class="row mb-4">
            <div class="col-6">
              <a-select
                allowClear
                size="large"
                placeholder="Please choose a billing period"
                @change="handleBillingPeriodChange"
                v-model="billingPeriod"
              >
                <a-select-option
                  v-for="(period, index) in billingPeriods"
                  :key="index"
                  :value="period"
                >
                  {{ period }}
                </a-select-option>
              </a-select>
            </div>
          </div>

          <!-- Plan Select -->
          <div class="row d-flex">
            <div class="col-6">
              <a-select
                allowClear
                size="large"
                placeholder="Please choose a plan"
                option-label-prop="label"
                @change="handlePlanChange"
                v-model="planValue"
              >
                <a-select-option
                  v-for="(plan, index) in productPlans"
                  :key="getPlanKey(plan)"
                  :value="index + ':' + (plan.domain ? plan.domain : '')"
                  :label="
                    plan.prettyName +
                    ' - $' +
                    plan.recurringPrice +
                    (plan.domain ? ' - ' + plan.domain : '')
                  "
                  style="border-bottom: 1px black"
                >
                  <a-tag v-if="plan.domain" color="purple">{{
                    plan.domain
                  }}</a-tag>
                  <a-statistic
                    :title="plan.description"
                    :value="plan.recurringPrice"
                    :precision="2"
                    prefix="$"
                    :suffix="'/ ' + plan.billingPeriod"
                    :value-style="{ color: '#3f8600' }"
                  >
                  </a-statistic>
                  <small>{{ plan.prettyName }}</small>
                </a-select-option>
              </a-select>
            </div>
          </div>

          <!-- List of chosen plans -->
          <div class="row mt-5">
            <div
              class="col-4 mt-3"
              :key="getPlanKey(plan)"
              v-for="plan in plans"
            >
              <a-card>
                <a-tag v-if="plan.domain" color="purple">{{
                  plan.domain
                }}</a-tag>
                <a-statistic
                  :title="plan.description"
                  :value="plan.recurringPrice"
                  :precision="2"
                  prefix="$"
                  :suffix="'/ ' + plan.billingPeriod"
                  :value-style="{ color: '#3f8600' }"
                >
                </a-statistic>
                <div>
                  <small>{{ plan.prettyName }}</small>
                </div>
                <a-button
                  type="link"
                  size="small"
                  style="color: red"
                  class="pl-0"
                  @click="removePlan(plan)"
                  ><small>Remove</small></a-button
                >
              </a-card>
            </div>
          </div>

          <p v-if="plans.length === 0">Please choose a plan to get started</p>

          <!-- Total cost  section -->
          <a-statistic
            class="mt-5"
            title="Total Price"
            :value="calculateTotal()"
            :precision="2"
            prefix="$"
            :value-style="{ color: '#3f8600', fontSize: '35px' }"
          >
          </a-statistic>

          <div class="mt-3">
            <small v-if="errors.integrityViolation" class="text-danger">{{
              errors.integrityViolation
            }}</small>
            <small v-if="errors.messageNotReadable" class="text-danger">{{
              errors.messageNotReadable
            }}</small>
            <small v-if="errors.generalException" class="text-danger">{{
              errors.generalException
            }}</small>
            <small v-if="errors.apiException" class="text-danger">{{
              errors.apiException
            }}</small>
          </div>

          <a-form-item>
            <a-button
              type="primary"
              html-type="submit"
              class="login-form-button mt-4"
              :loading="hasOps()"
            >
              Create Subscriptions
            </a-button>
          </a-form-item>
        </a-form>
      </div>

      <!-- Config modal -->
      <a-modal
        title="Configure product"
        :visible="visible"
        :confirm-loading="hasOps()"
        on-ok="checkDomain"
      >
        <span v-if="model.plan.product.name !== 'DOMAIN_NAME'">
          <p>Please choose a domain subscription to use</p>
          <a-select
            class="mb-4"
            style="width: 300px"
            size="large"
            placeholder="Domain subscriptions"
            @change="handleDomainSubChange"
            v-model="model.domain"
          >
            <a-select-option
              v-for="domain in domains"
              :key="domain.id"
              :value="domain.domain"
            >
              {{ domain.domain }}
            </a-select-option>
          </a-select>
        </span>

        <span v-if="model.plan.product.name === 'DOMAIN_NAME'">
          <p>Please choose a domain name to use</p>
          <a-form-item
            :has-feedback="errors.domain ? true : false"
            :validate-status="errors.domain ? 'error' : ''"
            :help="errors.domain ? errors.domain : ''"
          >
            <a-input
              allow-clear
              size="large"
              v-model="model.domain"
              placeholder="Domain name"
            />
          </a-form-item>
        </span>

        <!-- submit logic -->
        <template slot="footer">
          <a-button key="back" @click="handleCancel"> Return </a-button>
          <a-button
            v-if="model.plan.product.name !== 'DOMAIN_NAME'"
            key="submit"
            type="primary"
            :loading="hasOps()"
            @click="updateDomain"
          >
            Submit
          </a-button>
          <a-button
            v-if="model.plan.product.name === 'DOMAIN_NAME'"
            key="submit"
            type="primary"
            :loading="hasOps()"
            @click="checkDomain"
          >
            Check domain
          </a-button>
          <a-tooltip>
            <template slot="title">
              You will need to have admin access to the hosting panel of this
              domain
            </template>
            <a-button
              v-if="showExisting"
              type="danger"
              key="existing"
              @click="updateDomain"
            >
              Use already registered domain
            </a-button>
          </a-tooltip>
        </template>
      </a-modal>
    </div>
  </Navigation>
</template>

<script>
import baseMethods from "@/utils/baseMethods";
import { URLS } from "@/utils/constants";
import Navigation from "@/components/Navigation";
import { utils } from "../../utils/utils";

export default {
  name: "NewSubscription",
  data() {
    return {
      disabled: false,
      products: [],
      productPlans: [],
      subPlans: [],
      domains: [],
      users: [],
      selectedUser: undefined,
      billingPeriod: undefined,
      product: undefined,
      planValue: undefined,
      billingPeriods: ["QUARTERLY", "BIANNUAL", "ANNUAL"],
      subs: [],
      plans: [],
      model: {
        domain: "",
        plan: {
          product: "",
        },
      },
      errors: {},
      operations: 0,
      visible: false,
      showExisting: false,
    };
  },
  components: {
    Navigation,
  },
  mounted() {
    this.getSubscriptions();
    this.getProducts();
    if(this.isAdmin())
      this.getUsers();
  },
  methods: {
    handleUserChange(value) {
      console.log(`selected ${value}`);
      this.user = value;
    },
    isAdmin(){
      let isAdmin = false;
      let groups = utils.get('user_profile').userGroups;
      for(let i in groups){
        let groupItem = groups[i];
        if(groupItem.name === "ADMIN")
          isAdmin = true;
      }
      return isAdmin;
    },
    getUsers() {
      this.get(this.buildUrlWithParams(URLS.users, {size:"100",sort:"id,desc"}), true).then((res) => {
        if (!res) return;
        if (res.errors) return (this.errors = res.errors);

        this.users = res.body.content;
      });
    },
    handleDomainSubChange(domain) {
      this.model.domain = domain;
    },
    updateDomain() {
      this.model.plan.domain = this.model.domain;
      this.plans.push(this.model.plan);
      this.visible = false;
      this.model = {
        domain: "",
        plan: {
          product: "",
        },
      };
      this.showExisting = false;
      this.billingPeriod = undefined;
      this.product = undefined;
      this.planValue = undefined;
    },
    checkDomain() {
      this.get(
        URLS.products + "/domain-check?d=" + this.model.domain,
        true
      ).then((res) => {
        if (!res) return;
        if (res.errors) return (this.errors = res.errors);

        if (res.body.available) this.updateDomain();
        else {
          this.errors = { domain: "Domain already registered" };
          this.showExisting = true;
        }
      });
    },
    handleCancel() {
      console.log("Clicked cancel button");
      this.visible = false;
    },
    calculateTotal() {
      let total = 0;
      this.plans.forEach((plan) => {
        if (plan.deployed) return;
        total = total + plan.recurringPrice;
      });
      return total;
    },
    removePlan(plan) {
      let index = this.plans.indexOf(plan);
      if (index !== -1) {
        this.plans.splice(index, 1);
      }
    },
    getPlanKey(plan) {
      return plan.id + ":" + (plan.domain ? plan.domain : "");
    },
    handleProductChange(value) {
      console.log(`selected ${value}`);
      this.productPlans = [];
      this.product = value;
    },
    handleBillingPeriodChange(value) {
      console.log(`selected ${value}`);
      this.billingPeriod = value;
      this.getPlans(this.product, value);
    },
    handlePlanChange(value) {
      console.log(`selected ${value}`);
      let parts = value.split(":");
      console.log(parts[0], parts[1]);

      let planIndex = parts[0];
      let plan = this.productPlans[planIndex];

      // if we already added a plan for this product then remove plan and add this one instead
      // there should only be one plan per product
      if (this.plans.some((item) => item.product.name === plan.product.name)) {
        let removePlan = this.plans.find(
          (planItem) => planItem.product.name === plan.product.name
        );
        this.removePlan(removePlan);
      }

      // set domain subscriptions for config modal from subscriptions
      this.domains = this.subs.filter(
        (sub) => sub.plan.product.name === "DOMAIN_NAME"
      );

      // set domain subscriptions for config modal from plans in cart
      let additonalDomains = this.plans.filter(
        (plan) => plan.product.name === "DOMAIN_NAME"
      );
      this.domains = [...this.domains, ...additonalDomains];

      this.visible = true;
      this.model.plan = plan;
    },
    getPlans(productName, billingPeriod, closure = () => {}) {
      this.productPlans = [];
      let params = {
        size: 100,
        "product.name": productName,
        billingPeriod: billingPeriod,
      };
      this.get(this.buildUrlWithParams(URLS.plans, params), true).then(
        (res) => {
          if (!res) return;
          if (res.errors) return (this.errors = res.errors);

          // let subPlans = utils.copy(this.subPlans);

          this.productPlans = res.body.content;

          // // filter sub plans as we only need ones that match the particular product
          // subPlans = subPlans.filter(
          //   (plan) => plan.product.name === productName
          // );

          // // prepend sub plans to make sure they are on top of list
          // subPlans.forEach((plan) => {
          //   this.productPlans.unshift(plan);
          // });
          closure();
        }
      );
    },
    getProducts() {
      this.get(URLS.products + "?size=100", true).then((res) => {
        if (!res) return;
        if (res.errors) return (this.errors = res.errors);

        this.products = res.body.content;
      });
    },
    getSubscriptions() {
      this.get(
        URLS.subscriptionsByUser + "/" + utils.get("user_profile").uid,
        true
      ).then((res) => {
        if (!res) return;
        if (res.errors) return (this.errors = res.errors);

        this.subs = res;

        // get plans from subs
        this.subs.forEach((sub) => {
          // if (!this.subPlans.some((prod) => prod.name === sub.plan.name)) {
          let plan = utils.copy(sub.plan);
          plan["domain"] = sub.domain;
          plan["deployed"] = true;
          this.subPlans.push(plan);
          // }
        });
      });
    },
    onChange(checked) {
      console.log(`a-switch to ${checked}`);
    },
    handleSubmit(e) {
      e.preventDefault();
      this.errors = {};

      // transform plans to payload
      let payload = [];
      for (let i in this.plans) {
        let item = {};
        item["domain"] = this.plans[i].domain;
        item["planUid"] = this.plans[i].uid;

        // if user is admin, they can choose a user to assign subscriptions, else assigned logged in user
        item["userUid"] = this.isAdmin() ? this.selectedUser : utils.get("user_profile").uid;

        payload.push(item);
      }

      this.post(
        URLS.subscriptions,
        payload,
        "Subscription created successful",
        null,
        true
      ).then((res) => {
        if (!res) return;
        if (res.errors) return (this.errors = res.errors);

        setTimeout(() => {  console.log("Give kill bill time to do its thing!"); }, 2000);

        this.$router.push("/invoices");
      });
    },
    ...baseMethods,
  },
};
</script>

<style scoped>
.ant-form-item {
  margin-bottom: 10px;
}

.ant-divider-inner-text {
  font-weight: 250 !important;
}
</style>