<template>
  <Navigation>
    <a-page-header title="View Invoice" />
    <div class="mt-1 mx-4">
      <div v-if="hasOps()" class="text-center my-3">
        <a-spin size="large" />
      </div>
      <div class="row my-3">
        <div class="col justify-content-end">
          <a-button
              class="mb-1 mr-1"
              type="primary"
              :loading="hasOps()"
              @click="makeStewardPayment(model)"
          >
            Pay using Steward Pay
          </a-button>
          <a-button
              type="primary"
              :loading="hasOps()"
              @click="makeUpaywisePayment(model)"
          >
            Pay using Visa / Mastercard
          </a-button>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h3>Invoice Details</h3>
          <hr />
          <div :key="key" v-for="(value, key) in model" class="row mt-2">
            <div class="col-5" style="font-weight: bold">
              {{ changeCase(key) }}
            </div>
            <div class="col-7">
              {{ value }}
            </div>
          </div>
        </div>
        <div class="col-12 mt-5">
          <hr>
          <h3>Invoice Items</h3>
          <hr />
          <div class="row">
            <div class="col-2">Service Period</div>
            <div class="col-3">Description</div>
            <div class="col-5">Subscription ID</div>
            <div class="col">Amount</div>
          </div>
          <hr />
          <div
            :key="key"
            v-for="(value, key) in items"
            class="row mt-3 d-flex align-items-center"
          >
            <div class="col-2" style="">
              {{ value.startDate }} - {{ value.endDate }}
            </div>
            <div class="col-3">
              <div>{{ value.description }}</div>
            </div>
            <div class="col-5">
              <div>{{ value.subscriptionId }}</div>
            </div>
            <div class="col">
              {{ value.amount }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </Navigation>
</template>

<script>
import Navigation from "@/components/Navigation";
import { URLS } from "@/utils/constants";
import baseMethods from "@/utils/baseMethods";
import { utils } from "../../utils/utils";
import StewardBankSSO from "stewardbank-sso";

export default {
  name: "ViewInvoice",
  data() {
    return {
      errors: {},
      operations: 0,
      model: {},
      items: [],
      rate: 0
    };
  },
  components: {
    Navigation,
  },
  mounted() {
    let invoiceUid = this.$route.params.uid;
    this.getInvoice(invoiceUid);
    this.getRate();
  },
  methods: {
    changeCase(text) {
      return utils.camelCaseToSentenceCase(text);
    },
    getInvoice(invoiceUid) {
      this.get(`${URLS.invoices}/${invoiceUid}`, true).then((res) => {
        if (!res) return;
        if (res.errors) return (this.errors = res.errors);

        this.items = res.body.items;

        let toBeDeleted = ["trackingIds", "auditLogs", "bundleKeys", "items"];
        for (let k in toBeDeleted) {
          delete res.body[toBeDeleted[k]];
        }

        this.model = res.body;
      });
    },
    ...baseMethods,

  getRate() {
    this.get(URLS.settings + "/USD_RATE", true).then(
        (res) => {
          if (!res) return;
          if (res.errors) return (this.errors = res.errors);

          this.rate = parseInt(res.body.value);
        }
    );
  },

  async digestMessage(message) {
    const msgUint8 = new TextEncoder().encode(message);
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
  },

  async makeStewardPayment(record) {
    // let clientId = "opexa_pvt_ltd";
    // let clientSecret = "d2544bda-7c56-4914-91e1-2fa1d72b3a3b";
    // let encryptionKey = "13c221ac-4d46-4914-88e1-b57168615c39";

    let clientId = "zimbook_merchants_and_traders_pbc";
    let clientSecret = "fe6bcb54-0299-46e1-9bfb-7e58e52bc65a";
    let encryptionKey = "0c64590c-b6f5-4755-9c7f-1f6679da9978";

    let zwl_amount = record.amount * this.rate;

    let phone = JSON.parse(localStorage.user_profile).phone;
    let concat = clientId + encryptionKey + phone + zwl_amount;
    let hex = await this.digestMessage(concat);

    let sso = new StewardBankSSO(); // 1.
    sso.payment({ // 2.
      clientId: clientId,
      clientSecret: clientSecret,
      phone: phone,
      amount: zwl_amount,
      productUids: "",
      hash: hex,
      paymentProcessorLabel: "STEWARD",
      responseUrl: "https://api.zimscape.com/api/invoices/update/" + record.uid,
      sandbox: false
    }).then((response) => {
      console.log(response);
      if (response.success) {
        this.visible = false;

        this.$router.push("/payments");
      }
    });
  },

  async makeUpaywisePayment(record) {
    let terminalId = "SBZ10028";

    let clientId = "zimbook_merchants_and_traders_pbc";
    let clientSecret = "fe6bcb54-0299-46e1-9bfb-7e58e52bc65a";
    let encryptionKey = "0c64590c-b6f5-4755-9c7f-1f6679da9978";

    let phone = JSON.parse(localStorage.user_profile).phone;
    let concat = clientId + encryptionKey + phone + record.amount;
    let hex = await this.digestMessage(concat);

    let sso = new StewardBankSSO(); // 1.
    sso.payment({ // 2.
      clientId: clientId,
      clientSecret: clientSecret,
      phone: phone,
      amount: record.amount,
      productUids: "",
      terminalId: terminalId,
      hash: hex,
      paymentProcessorLabel: "PAYWISE",
      responseUrl: "https://api.zimscape.com/api/invoices/update/" + record.uid,
      sandbox: false
    }).then((response) => {
      console.log(response);
      if (response.success) {
        this.visible = false;

        this.$router.push("/payments");
      }
    });
  },
  },

};
</script>

<style>
</style>