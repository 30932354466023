<template>
  <div class="row d-flex justify-content-center h6 mt-5">
    <div class="col-12 text-center">
      <a href="#">FAQs</a> &middot;
      <a href="#">Privacy & Terms</a> &middot;
      <a href="#">Docs</a>
    </div>
  </div>
</template>

<script>
export default {
    name: 'Footer'
};
</script>

<style>
</style>