<template>
  <Navigation>
    <a-page-header title="View Environment" />
    <div class="mt-1 mx-4">
      <div v-if="hasOps()" class="text-center my-3">
        <a-spin size="large" />
      </div>
      <div class="row mr-4">
        <div class="col-12">
          <h3>Environment Details</h3>
          <hr />
          <div :key="key" v-for="(value, key) in data" class="row mt-2">
            <div class="col-5" style="font-weight: bold">
              {{ changeCase(key) }}
            </div>
            <div class="col-6">
              {{ value }}
            </div>
          </div>
        </div>

        <div class="col-12 mt-5">
          <hr />
          <div class="row d-flex align-items-center">
            <div class="col">
              <h3>Access Configs List</h3>
            </div>
            <div class="col text-right">
              <a-button
                v-if="data.label === 'dev'"
                type="primary"
                :loading="hasOps()"
                @click="showModal()"
              >
                Add Access Config
              </a-button>
            </div>
          </div>
          <hr />
          <div class="row d-flex align-items-center">
            <div class="col-2">Private Host</div>
            <div class="col-2">Private Port</div>
            <div class="col-3">Public Host</div>
            <div class="col-2">Public Port</div>
            <div class="col-3">UID</div>
          </div>
          <hr />
          <div
            v-for="config in accessConfigs"
            :key="config.id"
            class="row d-flex align-items-center mb-2"
          >
            <div class="col-2">{{ config.containerHost }}</div>
            <div class="col-2">{{ config.containerPort }}</div>
            <div class="col-3">{{ config.publicHost }}</div>
            <div class="col-2">{{ config.publicPort }}</div>
            <div class="col-3">{{ config.uid }}</div>
          </div>
        </div>
      </div>
    </div>
    <a-modal
      title="Add Access Config"
      :visible="visible"
      :confirm-loading="hasOps()"
      @ok="addAccessConfig"
      @cancel="visible = false"
    >
      Subdomain <small>(must be an active A-record. Use @ for main domain)</small>
      <a-form-item
        :has-feedback="errors.subdomain ? true : false"
        :validate-status="errors.subdomain ? 'error' : ''"
        :help="errors.subdomain ? errors.subdomain : ''"
      >
        <a-input
          type="text"
          allow-clear
          size="large"
          v-model="model.subdomain"
          placeholder="Sub-domain"
        />
      </a-form-item>
      <div>
        <small v-if="errors.user" class="text-danger">{{ errors.user }}</small>
        <small v-if="errors.integrityViolation" class="text-danger">{{
          errors.integrityViolation
        }}</small>
        <small v-if="errors.messageNotReadable" class="text-danger">{{
          errors.messageNotReadable
        }}</small>
        <small v-if="errors.generalException" class="text-danger">{{
          errors.generalException
        }}</small>
        <small v-if="errors.apiException" class="text-danger">{{
          errors.apiException
        }}</small>
      </div>
    </a-modal>
  </Navigation>
</template>

<script>
import Navigation from "@/components/Navigation";
import { URLS } from "@/utils/constants";
import baseMethods from "@/utils/baseMethods";
import { utils } from "../../utils/utils";

export default {
  name: "ViewEnvironment",
  data() {
    return {
      errors: {},
      operations: 0,
      data: {},
      model: {
        subdomain: "",
        environmentUid: this.$route.params.uid
      },
      accessConfigs: [],
      visible: false,
      environmentUid: this.$route.params.uid
    };
  },
  components: {
    Navigation,
  },
  mounted() {
    let environmentUid = this.$route.params.uid;
    this.getEnvironment(environmentUid);
  },
  methods: {
    addAccessConfig(){
      this.post(
        URLS.environments + "/access-configs/" + this.environmentUid,
        this.model,
        "Access config created successful",
        null,
        true
      ).then((res) => {
        if (!res) return;
        if (res.errors) return (this.errors = res.errors);

        this.visible = false;

        this.getEnvironment(this.environmentUid);
      });
    },
    showModal(){
      this.visible = true;
    },
    changeCase(text) {
      return utils.camelCaseToSentenceCase(text);
    },
    getEnvironment(environmentUid) {
      this.get(`${URLS.environments}/${environmentUid}`, true).then((res) => {
        if (!res) return;
        if (res.errors) return (this.errors = res.errors);

        this.accessConfigs = res.body.accessConfigs;

        let toBeDeleted = [
          "id",
          "createdBy",
          "created",
          "modified",
          "modifiedBy",
          "deleted",
          "name",
          "accessConfigs",
          "accessConfigsList",
        ];
        for (let k in toBeDeleted) {
          delete res.body[toBeDeleted[k]];
        }

        this.data = res.body;
      });
    },
    ...baseMethods,
  },
};
</script>

<style>
</style>