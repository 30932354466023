<template>
  <Navigation>
    <a-page-header title="View Subscription" />
    <div class="mt-1 mx-4">
      <div v-if="hasOps()" class="text-center my-3">
        <a-spin size="large" />
      </div>
      <div class="row">
        <div class="col-6">
          <h3>Subscription Details</h3>
          <hr />
          <div :key="key" v-for="(value, key) in model" class="row mt-2">
            <div class="col-3" style="font-weight: bold">
              {{ changeCase(key) }}
            </div>
            <div class="col-9">
              {{ value }}
            </div>
          </div>
        </div>
        <div class="col-6">
          <h3>Product Details</h3>
          <hr />
          <div class="mb-5">
            <div :key="key" v-for="(value, key) in product" class="row mt-2">
              <div class="col-3" style="font-weight: bold">
                {{ changeCase(key) }}
              </div>
              <div class="col-9">
                {{ value }}
              </div>
            </div>
          </div>
          <h3>Plan Details</h3>
          <hr />
          <div :key="key" v-for="(value, key) in plan" class="row mt-2">
            <div class="col-3" style="font-weight: bold">
              {{ changeCase(key) }}
            </div>
            <div class="col-9">
              {{ value }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </Navigation>
</template>

<script>
import Navigation from "@/components/Navigation";
import { URLS } from "@/utils/constants";
import baseMethods from "@/utils/baseMethods";
import { utils } from "../../utils/utils";

export default {
  name: "ViewSubscription",
  data() {
    return {
      errors: {},
      operations: 0,
      model: {},
      plan: {},
      product: {},
    };
  },
  components: {
    Navigation,
  },
  mounted() {
    let subscriptionUid = this.$route.params.uid;
    this.getSubscription(subscriptionUid);
  },
  methods: {
    changeCase(text) {
      return utils.camelCaseToSentenceCase(text);
    },
    getSubsctionFromBilling(subscriptionUid) {
      this.get(
        URLS.subscriptions + "/" + subscriptionUid + "/billing",
        true
      ).then((res) => {
        if (!res) return;
        if (res.errors) return (this.errors = res.errors);

        let model = utils.copy(this.model);

        model.billingStartDate = res.body.billingStartDate;
        model.chargedThroughDate = res.body.chargedThroughDate;
        model.subscriptionId = res.body.subscriptionId;

        this.model = model;
      });
    },
    getSubscription(subscriptionUid) {
      this.get(`${URLS.subscriptions}/${subscriptionUid}`, true).then((res) => {
        if (!res) return;
        if (res.errors) return (this.errors = res.errors);

        this.plan = res.body.plan;
        this.product = res.body.plan.product;

        let planToBeDeleted = [
          "id",
          "createdBy",
          "created",
          "modified",
          "modifiedBy",
          "deleted",
          "name",
        ];
        for (let k in planToBeDeleted) {
          delete this.plan[planToBeDeleted[k]];
        }

        let productToBeDeleted = [
          "id",
          "createdBy",
          "created",
          "modified",
          "modifiedBy",
          "deleted",
          "meta",
          "featured",
          "containerImage",
          "targetPorts",
          "name",
        ];
        for (let k in productToBeDeleted) {
          delete this.product[productToBeDeleted[k]];
        }

        delete this.plan.product;

        let toBeDeleted = ["bundleUrl", "plan", "meta", "metaMap", "killBillId"];

        for (let k in toBeDeleted) {
          delete res.body[toBeDeleted[k]];
        }

        this.model = res.body;
        this.getSubsctionFromBilling(subscriptionUid);
      });
    },
    ...baseMethods,
  },
};
</script>

<style>
</style>