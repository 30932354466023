<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">
          <img
            src="../assets/images/ZimScape-02.png"
            alt=""
            style="width: 150px"
          />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item" style="display: inline">
              <div class="row d-flex flex-row align-items-center mr-3">
                <div
                  class="col"
                  v-if="profile.status !== '__KILLBILL__CLEAR__OVERDUE_STATE__'"
                >
                  <a-button type="link" class="text-dark">
                    Status: {{ profile.status }}
                  </a-button>
                </div>
                <div class="col">
                  <a-button type="link" class="text-dark"
                    >Balance (USD)</a-button
                  >
                </div>
                <div class="col">
                  <a-statistic
                    class="pt-0 mt-0"
                    prefix="$"
                    :precision="2"
                    :value="profile.accountBalance"
                    :value-style="{
                      color: profile.accountBalance > 0 ? 'red' : 'green',
                    }"
                  />
                </div>
                <div class="col" v-if="profile.accountBalance > 0">
                  <a-button type="primary" :loading="hasOps()" @click="showModal()">
                    Pay Account Balance
                  </a-button>
                </div>
              </div>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#"> {{ getUserProfile.email }} </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click="logout()"> Logout </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div class="row">
      <div class="col-lg-2 col-12">
        <a-menu
            class="d-block d-lg-none"
            v-model="current"
            :default-selected-keys="['Users']"
            mode="horizontal"
            @click="handleClick"
        >
          <a-menu-item key="Environments" @click="navigateTo('/environments')">
            <template #icon>
              <PieChartOutlined />
            </template>
            <span>Environments</span>
          </a-menu-item>
          <a-menu-item key="Payments" @click="navigateTo('/payments')">
            Payments
          </a-menu-item>
          <a-menu-item key="Invoices" @click="navigateTo('/invoices')">
            Invoices
          </a-menu-item>
          <a-menu-item
              key="Subscriptions"
              @click="navigateTo('/subscriptions')"
          >
            Subscriptions
          </a-menu-item>
          <a-menu-item key="Users" @click="navigateTo('/users')">
            Users
          </a-menu-item>
        </a-menu>
        <a-menu
            class="d-none d-lg-block"
            v-model="current"
            :default-selected-keys="['Users']"
            mode="inline"
            @click="handleClick"
        >
          <a-menu-item key="Environments" @click="navigateTo('/environments')">
            <template #icon>
              <PieChartOutlined />
            </template>
            <span>Environments</span>
          </a-menu-item>
          <a-menu-item key="Payments" @click="navigateTo('/payments')">
            Payments
          </a-menu-item>
          <a-menu-item key="Invoices" @click="navigateTo('/invoices')">
            Invoices
          </a-menu-item>
          <a-menu-item
              key="Subscriptions"
              @click="navigateTo('/subscriptions')"
          >
            Subscriptions
          </a-menu-item>
          <a-menu-item key="Users" @click="navigateTo('/users')">
            Users
          </a-menu-item>
        </a-menu>
      </div>
      <div class="col-lg-10 col-12 mx-lg-0 mx-2">
        <slot></slot>
      </div>
    </div>
    <Footer />

    <a-modal
      title="Manual Payment"
      :visible="visible"
      :confirm-loading="hasOps()"
      @ok="makePayment"
      @cancel="visible = false"
    >
      <p>
        Are you sure you want to record a manual payment for all outstanding invoices on 
        <strong>{{ paymentCandidate.email }}</strong>'s profile?
      </p>
      <div>
        <small v-if="errors.integrityViolation" class="text-danger">{{
          errors.integrityViolation
        }}</small>
        <small v-if="errors.messageNotReadable" class="text-danger">{{
          errors.messageNotReadable
        }}</small>
        <small v-if="errors.generalException" class="text-danger">{{
          errors.generalException
        }}</small>
        <small v-if="errors.apiException" class="text-danger">{{
          errors.apiException
        }}</small>
      </div>
    </a-modal>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import { utils } from "../utils/utils";
import { URLS } from "@/utils/constants";
import baseMethods from "../utils/baseMethods";

export default {
  data() {
    return {
      current: ["Users"],
      profile: {},
      operations: 0,
      visible: false,
      paymentCandidate: {},
      errors: {},
      collapsed: false,
    };
  },
  components: {
    Footer,
  },
  mounted() {
    let route = this.$route.name;
    this.current = [route];
    this.fetchUserProfile();
  },
  computed: {
    getUserProfile() {
      return utils.get("user_profile");
    },
  },
  methods: {
    toggleCollapsed () {
      this.collapsed = !this.collapsed;
    },
    async makePayment() {
      return this.get(
        URLS.payments + "/balance",
        true
      ).then((res) => {
        if (!res) return;
        if (res.errors) return (this.errors = res.errors);

        this.visible = false;

        this.$router.push("/payments");
      });
    },
    showModal() {
      this.paymentCandidate = utils.get('user_profile');
      this.visible = true;
    },
    fetchUserProfile() {
      this.get(`${URLS.users}/${utils.get("user_profile").uid}`, true).then(
        (res) => {
          if (!res) return;
          if (res.errors) return (this.errors = res.errors);

          // save profile to disk
          utils.save("user_profile", res.body);
          this.profile = res.body;
        }
      );
    },
    navigateTo(to) {
      if (to != this.$route.path) this.$router.push(to);
    },
    handleClick(e) {
      console.log("click", e);
      this.current = [e.key];
    },
    logout() {
      localStorage.clear();
      this.$router.push("/login");
    },
    ...baseMethods,
  },
};
</script>

<style>
</style>