<template>
  <div
    class="row d-flex justify-content-center align-items-center"
    style="height: 100vh"
  >
    <div class="col-3">
      <div class="text-center">
        <img
          src="../assets/images/ZimScape-02.png"
          alt=""
          class="my-4"
          style="width: 200px"
        />
      </div>
      <p class="text-center mb-4 w-75 mx-auto">
        Please enter your registered email address and we will send an email
        with further instructions
      </p>
      <a-form
        id="components-form-demo-normal-login"
        :form="form"
        class="login-form"
        @submit="handleSubmit"
      >
        <a-form-item
          :has-feedback="errors.apiException ? true : false"
          :validate-status="errors.apiException ? 'error' : ''"
          :help="errors.apiException ? errors.apiException : ''"
        >
          <a-input
            size="large"
            v-model="model.email"
            placeholder="Email address"
            style="text-indent: 5px !important"
          >
            <a-icon
              slot="prefix"
              type="mail"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
        <a-form-item class="mb-2">
          <a-button
            size="large"
            type="primary"
            html-type="submit"
            class="login-form-button"
            :loading="hasOps()"
          >
            Submit
          </a-button>
        </a-form-item>
        <div class="row">
          <div class="col-6">
            Or
            <router-link to="/login" class="login-form-forgot" href="">
              Back to login
            </router-link>
          </div>
          <div class="col-6 text-right"></div>
        </div>
        <Footer></Footer>
      </a-form>
    </div>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import { URLS } from "@/utils/constants";
import baseMethods from '../utils/baseMethods';

export default {
  name: "RequestReset",
  data() {
    return {
      errors: {},
      model: {},
      operations: 0
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "normal_login" });
  },
  components: {
    Footer,
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.errors = {};

      this.postNoAuth(
        URLS.requestReset,
        this.model,
        "Reset request successful",
        null,
        true
      ).then((res) => {
        if (!res) return;
        if (res.errors) return (this.errors = res.errors);

        this.$router.push("/login");
      });
    },
    ...baseMethods
  },
};
</script>

<style>
</style>