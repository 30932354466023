<template>
  <div>
    <Navigation>
      <a-page-header title="Invoices" class="pl-0"/>
      <div class="mr-3">
        <div v-if="hasOps()" class="text-center my-3">
          <a-spin size="large"/>
        </div>
        <div class="my-3 row">
          <div class="col-6">
            <!-- <a-select
              default-value="delete"
              style="min-width: 80px"
              class="mr-5"
            >
              <a-select-option value="delete" @click="doBulkAction('delete')">
                Delete
              </a-select-option>
            </a-select> -->
            <a-input-search
                allow-clear
                placeholder="Search by ID, Number, Amount or Balance"
                style="width: 400px"
                @search="onSearch"
            />
          </div>
          <div class="col-6 text-right"></div>
        </div>
        <a-table
            :columns="columns"
            :data-source="data"
            :pagination="pagination"
            :row-selection="{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange,
            }"
            @change="handleTableChange"
            rowKey="id"
        >
          <span slot="created" slot-scope="created">
            {{ formatDate(created) }}
          </span>
          <span slot="actions" slot-scope="text, record">
            <a-select
                default-value="view"
                style="min-width: 200px"
            >
              <a-select-option
                  value="manual"
                  v-if="record.balance > 0 && userIsAdmin"
                  @click="showModal(record)"
              >
                Manual payment
              </a-select-option>
              <a-select-option
                  value="steward"
                  v-if="record.balance > 0"
                  @click="makeStewardPayment(record)"
              >
                Pay using Steward Pay
              </a-select-option>
              <a-select-option
                  value="paywise"
                  v-if="record.balance > 0"
                  @click="makeUpaywisePayment(record)"
              >
                Pay using Visa / Mastercard
              </a-select-option>
              <a-select-option
                  value="redeploy"
                  v-if="record.balance === 0"
                  @click="showRedeployModal(record)"
              >
                Re-Deploy
              </a-select-option>
              <a-select-option value="view">
                <router-link :to="'/invoices/view/' + record.uid">
                  View
                </router-link>
              </a-select-option>
              <a-select-option value="invoiceHtml">
                <a :href="baseUrl + '/public/invoices/' + record.uid + '/html'" target="_blank">
                  View Generated Invoice
                </a>
              </a-select-option>
            </a-select>
          </span>
        </a-table>
      </div>
    </Navigation>
    <a-modal
        title="Manual Payment"
        :visible="visible"
        :confirm-loading="hasOps()"
        @ok="makePayment"
        @cancel="visible = false"
    >
      <p>
        Are you sure you want to record a manual payment of
        <strong>${{ paymentCandidate.amount }}</strong> for invoice
        {{ paymentCandidate.invoiceId }}?
      </p>
      <div>
        <small v-if="errors.integrityViolation" class="text-danger">{{
            errors.integrityViolation
          }}</small>
        <small v-if="errors.messageNotReadable" class="text-danger">{{
            errors.messageNotReadable
          }}</small>
        <small v-if="errors.generalException" class="text-danger">{{
            errors.generalException
          }}</small>
        <small v-if="errors.apiException" class="text-danger">{{
            errors.apiException
          }}</small>
      </div>
    </a-modal>
    <a-modal
        title="Re-Deploy related subscriptions"
        :visible="redeployVisible"
        :confirm-loading="hasOps()"
        @ok="reDeploy"
        @cancel="redeployVisible = false"
    >
      <p>
        Are you sure you want to re-deploy subscriptions related to this
        payment?
      </p>
      <div>
        <small v-if="errors.integrityViolation" class="text-danger">{{
            errors.integrityViolation
          }}</small>
        <small v-if="errors.messageNotReadable" class="text-danger">{{
            errors.messageNotReadable
          }}</small>
        <small v-if="errors.generalException" class="text-danger">{{
            errors.generalException
          }}</small>
        <small v-if="errors.apiException" class="text-danger">{{
            errors.apiException
          }}</small>
      </div>
    </a-modal>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import baseMethods from "../../utils/baseMethods";
import {URLS} from "@/utils/constants";
import StewardBankSSO from "stewardbank-sso"

import moment from "moment";
import {utils} from "../../utils/utils";

const columns = [
  {
    title: "Invoice Number",
    dataIndex: "invoiceNumber",
    key: "invoiceNumber",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "Balance",
    dataIndex: "balance",
    key: "balance",
  },
  {
    title: "User",
    key: "user.email",
    dataIndex: "user.email",
  },
  {
    title: "Invoice Date",
    key: "invoiceDate",
    dataIndex: "invoiceDate",
  },
  {
    title: "Action",
    key: "action",
    scopedSlots: {customRender: "actions"},
  },
];

export default {
  name: "Invoices",
  data() {
    return {
      data: [],
      columns,
      selectedRowKeys: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 1,
      },
      params: {
        size: 10,
        page: 0,
        sort: "id,desc",
      },
      rate: 0,
      operations: 0,
      visible: false,
      redeployVisible: false,
      paymentCandidate: {},
      redeployCandidate: {},
      errors: {},
      profile: {},
      baseUrl: process.env.VUE_APP_SERVER_URL,
    };
  },
  mounted() {
    this.getInvoices();
    this.getRate();
  },
  computed: {
    userIsAdmin() {
      let profile = utils.get("user_profile");
      let index = profile.userGroups.findIndex(group => group.name === "ADMIN");
      return index !== -1;
    },
  },
  components: {
    Navigation,
  },
  methods: {
    onSearch(value) {
      console.log(value);
      let params = utils.copy(this.params);
      params.userUid = value;
      params.amount = value;
      params.balance = value;
      params.invoiceId = value;
      params.invoiceNumber = value;
      params["user.email"] = value;
      this.params = params;
      this.getInvoices();
    },
    handleTableChange(pagination) {
      console.log(pagination);
      const pager = {...this.pagination};
      pager.current = pagination.current;
      this.pagination = pager;
      this.params.size = pager.pageSize;
      this.params.page = pager.current - 1;
      this.getInvoices();
    },
    async doBulkAction(action) {
      switch (action) {
        case "delete":
          for (let i in this.selectedRowKeys) {
            this.paymentCandidate = this.data.find(
                (item) => item.id === this.selectedRowKeys[i]
            );
            await this.deleteInvoice();
            this.paymentCandidate = {};
          }
      }
    },
    reDeploy() {
      this.get(
          URLS.invoices + "/redeploy/" + this.redeployCandidate.uid,
          true
      ).then((res) => {
        if (!res) return;
        if (res.errors) return (this.errors = res.errors);

        this.redeployVisible = false;

        this.getInvoices();
      });
    },
    async makePayment() {
      let payload = {
        userUid: utils.get("user_profile").uid,
        targetInvoiceId: this.paymentCandidate.invoiceId,
        purchasedAmount: this.paymentCandidate.amount,
      };
      return this.post(
          URLS.payments,
          payload,
          "Payment made successfully",
          null,
          true
      ).then((res) => {
        if (!res) return;
        if (res.errors) return (this.errors = res.errors);

        this.visible = false;

        this.$router.push("/payments");
      });
    },
    showModal(record) {
      this.paymentCandidate = record;
      this.visible = true;
    },

    getRate() {
      this.get(URLS.settings + "/USD_RATE", true).then(
          (res) => {
            if (!res) return;
            if (res.errors) return (this.errors = res.errors);

            this.rate = parseInt(res.body.value);
          }
      );
    },

    async digestMessage(message) {
      const msgUint8 = new TextEncoder().encode(message);
      const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
      return hashHex;
    },

    async makeStewardPayment(record) {
      // let clientId = "opexa_pvt_ltd";
      // let clientSecret = "d2544bda-7c56-4914-91e1-2fa1d72b3a3b";
      // let encryptionKey = "13c221ac-4d46-4914-88e1-b57168615c39";

      let clientId = "zimbook_merchants_and_traders_pbc";
      let clientSecret = "fe6bcb54-0299-46e1-9bfb-7e58e52bc65a";
      let encryptionKey = "0c64590c-b6f5-4755-9c7f-1f6679da9978";

      let zwl_amount = record.amount * this.rate;

      let phone = JSON.parse(localStorage.user_profile).phone;
      let concat = clientId + encryptionKey + phone + zwl_amount;
      let hex = await this.digestMessage(concat);

      let sso = new StewardBankSSO(); // 1.
      sso.payment({ // 2.
        clientId: clientId,
        clientSecret: clientSecret,
        phone: phone,
        amount: zwl_amount,
        productUids: "",
        hash: hex,
        paymentProcessorLabel: "STEWARD",
        responseUrl: "https://api.zimscape.com/api/invoices/update/" + record.uid,
        sandbox: false
      }).then((response) => {
        console.log(response);
        if (response.success) {
          this.visible = false;

          this.$router.push("/payments");
        }
      });
    },

    async makeUpaywisePayment(record) {
      let terminalId = "SBZ10028";

      let clientId = "zimbook_merchants_and_traders_pbc";
      let clientSecret = "fe6bcb54-0299-46e1-9bfb-7e58e52bc65a";
      let encryptionKey = "0c64590c-b6f5-4755-9c7f-1f6679da9978";

      let phone = JSON.parse(localStorage.user_profile).phone;
      let concat = clientId + encryptionKey + phone + record.amount;
      let hex = await this.digestMessage(concat);

      let sso = new StewardBankSSO(); // 1.
      sso.payment({ // 2.
        clientId: clientId,
        clientSecret: clientSecret,
        phone: phone,
        amount: record.amount,
        productUids: "",
        terminalId: terminalId,
        hash: hex,
        paymentProcessorLabel: "PAYWISE",
        responseUrl: "https://api.zimscape.com/api/invoices/update/" + record.uid,
        sandbox: false
      }).then((response) => {
        console.log(response);
        if (response.success) {
          this.visible = false;

          this.$router.push("/payments");
        }
      });
    },

    showRedeployModal(record) {
      this.redeployCandidate = record;
      this.redeployVisible = true;
    },
    formatDate(dateString) {
      return moment(dateString).format("DD-MMM-YYYY HH:mm:ss");
    },
    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    getInvoices() {
      this.get(this.buildUrlWithParams(URLS.invoices, this.params), true).then(
          (res) => {
            if (!res) return;
            if (res.errors) return (this.errors = res.errors);

            this.data = res.body.content;
            this.pagination.total = res.body.totalElements;
          }
      );
    },
    ...baseMethods,
  },
};
</script>
<style>
</style>