<template>
  <div>
    <Navigation/>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation"

export default {
  name: "Home",
  components: {
    Navigation
  },
};
</script>

<style>
</style>