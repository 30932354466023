<template>
  <Navigation>
    <a-page-header
      title="Edit Subscription"
      sub-title="Edit Zimscape subscription"
    />
    <div class="row d-flex ml-3 align-items-center mt-1">
      <div class="col-8">
        <a-form
          id="components-form-demo-normal-login"
          class="login-form"
          @submit="handleSubmit"
        >
          <p class="text-center">
            Plan prices will be adjusted proportionally according to negative or <br> positive price difference 
            as well as according to the number of days left in the plan duration
          </p>
          <!-- Product Select -->
          <div class="row d-flex">
            <div class="col-6">
              <a-select
                disabled
                v-model="productName"
                size="large"
                placeholder="Please choose a product"
                option-label-prop="label"
                @change="handleProductChange"
              >
                <a-select-option
                  v-for="product in products"
                  :key="product.id"
                  :value="product.name"
                  :label="product.prettyName"
                >
                  <strong>{{ product.prettyName }}</strong>
                  <div>{{ product.description }}</div>
                </a-select-option>
              </a-select>
            </div>

            <!-- Plan Select -->
            <div class="col-6">
              <a-select
                v-model="planName"
                size="large"
                placeholder="Please choose a plan"
                option-label-prop="label"
                @change="handlePlanChange"
              >
                <a-select-option
                  v-for="(plan, index) in productPlans"
                  :key="getPlanKey(plan)"
                  :value="index + ':' + (plan.domain ? plan.domain : '')"
                  :label="
                    plan.prettyName +
                    ' - $' +
                    plan.recurringPrice +
                    (plan.domain ? ' - ' + plan.domain : '')
                  "
                  style="border-bottom: 1px black"
                >
                  <a-tag v-if="plan.domain" color="purple">{{
                    plan.domain
                  }}</a-tag>
                  <a-statistic
                    :title="plan.description"
                    :value="plan.recurringPrice"
                    :precision="2"
                    prefix="$"
                    :suffix="'/ ' + plan.billingPeriod"
                    :value-style="{ color: '#3f8600' }"
                  >
                  </a-statistic>
                  <small>{{ plan.prettyName }}</small>
                </a-select-option>
              </a-select>
            </div>
          </div>

          <!-- List of chosen plans -->
          <div class="row mt-5">
            <div
              class="col-4 mt-3"
              :key="getPlanKey(plan)"
              v-for="plan in plans"
            >
              <a-card>
                <a-tag v-if="plan.domain" color="purple">{{
                  plan.domain
                }}</a-tag>
                <a-statistic
                  :title="plan.description"
                  :value="plan.recurringPrice"
                  :precision="2"
                  prefix="$"
                  :suffix="'/ ' + plan.billingPeriod"
                  :value-style="{ color: '#3f8600' }"
                >
                </a-statistic>
                <div>
                  <small>{{ plan.prettyName }}</small>
                </div>
                <div v-if="subscription" class="my-3" style="font-style:italic">
                  Current Billing Period: <br>
                  {{
                    `${subscription.billingStartDate} - ${subscription.chargedThroughDate}`
                  }}
                </div>

                <a-button
                  type="link"
                  size="small"
                  style="color: red"
                  class="pl-0"
                  @click="removePlan(plan)"
                  ><small>Remove</small></a-button
                >
              </a-card>
            </div>
          </div>

          <p v-if="plans.length === 0">Please choose a plan to get started</p>

          <!-- Total cost  section -->
          <a-statistic
            class="mt-5"
            title="Total Price"
            :value="calculateTotal()"
            :precision="2"
            prefix="$"
            :value-style="{ color: '#3f8600', fontSize: '35px' }"
          >
          </a-statistic>

          <div>
            <small v-if="errors.integrityViolation" class="text-danger">{{
              errors.integrityViolation
            }}</small>
            <small v-if="errors.messageNotReadable" class="text-danger">{{
              errors.messageNotReadable
            }}</small>
            <small v-if="errors.generalException" class="text-danger">{{
              errors.generalException
            }}</small>
            <small v-if="errors.apiException" class="text-danger">{{
              errors.apiException
            }}</small>
          </div>

          <a-form-item>
            <a-button
              type="primary"
              html-type="submit"
              class="login-form-button mt-5"
              :loading="hasOps()"
            >
              Update Subscription
            </a-button>
          </a-form-item>
        </a-form>
      </div>

      <!-- Domain name config modal -->
      <a-modal
        title="Configure domain name"
        :visible="visible"
        :confirm-loading="hasOps()"
        on-ok="checkDomain"
      >
        <p>Please choose a domain name to use</p>
        <a-form-item
          :has-feedback="errors.domain ? true : false"
          :validate-status="errors.domain ? 'error' : ''"
          :help="errors.domain ? errors.domain : ''"
        >
          <a-input
            allow-clear
            size="large"
            v-model="model.domain"
            placeholder="Domain name"
          />
        </a-form-item>
        <template slot="footer">
          <a-button key="back" @click="handleCancel"> Return </a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="hasOps()"
            @click="checkDomain"
          >
            Submit
          </a-button>
          <a-tooltip>
            <template slot="title">
              You will need to have admin access to the hosting panel of this
              domain
            </template>
            <a-button
              v-if="showExisting"
              type="danger"
              key="existing"
              @click="updateDomain"
            >
              Use already registered domain
            </a-button>
          </a-tooltip>
        </template>
      </a-modal>
    </div>
  </Navigation>
</template>

<script>
import baseMethods from "@/utils/baseMethods";
import { URLS } from "@/utils/constants";
import Navigation from "@/components/Navigation";
import { utils } from "../../utils/utils";

export default {
  name: "EditSubscription",
  data() {
    return {
      products: [],
      productPlans: [],
      subPlans: [],

      productName: "",
      planName: "",

      subs: [],
      plans: [],
      model: {
        domain: "",
        plan: {},
        uid: ""
      },
      subscription: {},
      errors: {},
      operations: 0,
      visible: false,
      showExisting: false,
    };
  },
  components: {
    Navigation,
  },
  async mounted() {
    let subscriptionUid = this.$route.params.uid;
    await this.getSubscription(subscriptionUid);
    this.getSubsctionFromBilling(subscriptionUid);
    // this.getProducts();
  },
  methods: {
    getSubsctionFromBilling(subscriptionUid) {
      this.get(
        URLS.subscriptions + "/" + subscriptionUid + "/billing",
        true
      ).then((res) => {
        if (!res) return;
        if (res.errors) return (this.errors = res.errors);

        this.subscription = res.body;
      });
    },
    getSubscription(subscriptionUid) {
      return this.get(`${URLS.subscriptions}/${subscriptionUid}`, true).then(
        async (res) => {
          if (!res) return;
          if (res.errors) return (this.errors = res.errors);

          // this.model = res.body;
          this.model.domain = res.body.domain;
          this.model.uid = res.body.uid;

          // get subs after we've gotten the domain
          await this.getSubscriptions();

          this.productName = res.body.plan.product.name;
          await this.handleProductChange(this.productName);

          let index = this.productPlans.findIndex(
            (plan) => plan.name === res.body.plan.name
          );
          let domain = this.productPlans[index].domain;
          this.planName = `${index}:${domain ? domain : ""}`;
          this.handlePlanChange(this.planName);
        }
      );
    },
    updateDomain() {
      this.model.plan.domain = this.model.domain;
      this.plans.push(this.model.plan);
      this.visible = false;
      this.model = {};
    },
    checkDomain() {
      this.get(
        URLS.products + "/domain-check?d=" + this.model.domain,
        true
      ).then((res) => {
        if (!res) return;
        if (res.errors) return (this.errors = res.errors);

        if (res.body.available) this.updateDomain();
        else {
          this.errors = { domain: "Domain already registered" };
          this.showExisting = true;
        }
      });
    },
    handleCancel() {
      console.log("Clicked cancel button");
      this.visible = false;
    },
    calculateTotal() {
      let total = 0;
      this.plans.forEach((plan) => {
        if (plan.domain) return;
        total = total + plan.recurringPrice;
      });
      return total;
    },
    removePlan(plan) {
      let index = this.plans.indexOf(plan);
      if (index !== -1) {
        this.plans.splice(index, 1);
      }
    },
    getPlanKey(plan) {
      return plan.id + ":" + (plan.domain ? plan.domain : "");
    },
    handlePlanChange(value) {
      console.log(`selected ${value}`);
      let parts = value.split(":");
      console.log(parts[0], parts[1]);

      let planIndex = parts[0];
      let plan = this.productPlans[planIndex];

      // if we already added a plan for this product then remove plan and add this one instead
      // there should only be one plan per product
      if (this.plans.some((item) => item.product.name === plan.product.name)) {
        let removePlan = this.plans.find(
          (planItem) => planItem.product.name === plan.product.name
        );
        this.removePlan(removePlan);
      }

      // new domain name plans must go through a config step before being added to plans list
      if (plan.product.name === "DOMAIN_NAME" && !plan.domain) {
        this.visible = true;
        this.model.plan = plan;
      } else {
        this.plans.push(plan);
      }
    },
    async handleProductChange(value) {
      console.log(`selected ${value}`);
      this.productPlans = [];
      await this.getPlans(value);
    },
    async getPlans(productName) {
      this.productPlans = [];
      let params = {
        size: 100,
        "product.name": productName,
      };
      await this.get(this.buildUrlWithParams(URLS.plans, params), true).then(
        (res) => {
          if (!res) return;
          if (res.errors) return (this.errors = res.errors);

          let subPlans = utils.copy(this.subPlans);

          this.productPlans = res.body.content;

          // filter sub plans as we only need ones that match the particular product
          subPlans = subPlans.filter(
            (plan) => plan.product.name === productName
          );

          // prepend sub plans to make sure they are on top of list
          subPlans.forEach((plan) => {
            this.productPlans.unshift(plan);
          });
        }
      );
    },
    getProducts() {
      this.get(URLS.products + "?size=100", true).then((res) => {
        if (!res) return;
        if (res.errors) return (this.errors = res.errors);

        this.products = res.body.content;
      });
    },
    getSubscriptions() {
      return this.get(
        URLS.subscriptionsByUser +
          "/" +
          utils.get("user_profile").uid +
          "/domain/" +
          this.model.domain,
        true
      ).then((res) => {
        if (!res) return;
        if (res.errors) return (this.errors = res.errors);

        this.subs = res;

        // get plans from subs
        this.subs.forEach((sub) => {
          // if (!this.subPlans.some((prod) => prod.name === sub.plan.name)) {
          let plan = utils.copy(sub.plan);
          plan["domain"] = sub.domain;
          this.subPlans.push(plan);
          // }
        });
      });
    },
    onChange(checked) {
      console.log(`a-switch to ${checked}`);
    },
    handleSubmit(e) {
      e.preventDefault();

      // transform plans to payload
      let payload = [];
      for (let i in this.plans) {
        let item = {};
        item["domain"] = this.model.domain;
        item["userUid"] = utils.get("user_profile").uid;
        item["planUid"] = this.plans[i].uid;
        item["uid"] = this.model.uid;
        payload.push(item);
      }

      this.put(
        URLS.subscriptions,
        payload[0], // payload should be an obj and list only has one item
        "Subscription created successful",
        null,
        true
      ).then((res) => {
        if (!res) return;
        if (res.errors) return (this.errors = res.errors);

        setTimeout(() => {  console.log("Give kill bill time to do its thing!"); }, 2000);

        this.$router.push("/subscriptions");
      });
    },
    ...baseMethods,
  },
};
</script>

<style scoped>
.ant-form-item {
  margin-bottom: 10px;
}

.ant-divider-inner-text {
  font-weight: 250 !important;
}
</style>