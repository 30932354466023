
export const URLS = {
    login: "/public/login",
    register: "/public/register",
    requestReset: "/public/reset",
    userByEmail: "/users/email",
    reset: "/users/password",
    users: "/users",
    subscriptions: "/subscriptions",
    subscriptionsByUser: "/subscriptions/user",
    products: "/products",
    plans: "/plans",
    invoices: "/invoices",
    payments: "/payments",
    environments: "/environments",
    emails: "/emails/users",
    settings: "/public/setting",
};