<template>
  <div>
    <Navigation>
      <a-page-header title="Payments" class="pl-0"/>
      <div class="mr-3">
        <div v-if="hasOps()" class="text-center my-3">
          <a-spin size="large" />
        </div>
        <div class="my-3 row">
          <div class="col-6">
            <a-select
              default-value="delete"
              style="min-width: 80px"
              class="mr-5"
            >
              <a-select-option value="delete" @click="doBulkAction('delete')">
                Delete
              </a-select-option>
            </a-select>
            <a-input-search
              allow-clear
              placeholder="Search by ID, Number, Amount or Balance"
              style="width: 400px"
              @search="onSearch"
            />
          </div>
          <div class="col-6 text-right">
          </div>
        </div>
        <a-table
          :columns="columns"
          :data-source="data"
          :pagination="pagination"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
          @change="handleTableChange"
          rowKey="id"
        >
          <span slot="created" slot-scope="created">
            {{ formatDate(created) }}
          </span>
          <span slot="actions" slot-scope="text, record">
            <a-select default-value="view" style="min-width: 150px">
              <a-select-option value="view">
                <router-link :to="'/payments/view/' + record.uid">
                  View
                </router-link>
              </a-select-option>
              <a-select-option value="redeploy" @click="showModal(record)">
                  Re-Deploy
              </a-select-option>
            </a-select>
          </span>
        </a-table>
      </div>
    </Navigation>
    <a-modal
      title="Re-Deploy related subscriptions"
      :visible="visible"
      :confirm-loading="hasOps()"
      @ok="reDeploy"
      @cancel="visible = false"
    >
      <p>
        Are you sure you want to re-deploy subscriptions related to this payment?
      </p>
      <div>
        <small v-if="errors.integrityViolation" class="text-danger">{{
          errors.integrityViolation
        }}</small>
        <small v-if="errors.messageNotReadable" class="text-danger">{{
          errors.messageNotReadable
        }}</small>
        <small v-if="errors.generalException" class="text-danger">{{
          errors.generalException
        }}</small>
        <small v-if="errors.apiException" class="text-danger">{{
          errors.apiException
        }}</small>
      </div>
    </a-modal>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import baseMethods from "../../utils/baseMethods";
import { URLS } from "@/utils/constants";

import moment from "moment";
import { utils } from "../../utils/utils";

const columns = [
  {
    title: "User",
    dataIndex: "user.email",
    key: "user.email",
  },
  {
    title: "Purchased Amount",
    dataIndex: "purchasedAmount",
    key: "purchasedAmount",
  },
  {
    title: "Refunded Amount",
    dataIndex: "refundedAmount",
    key: "refundedAmount",
  },
  {
    title: "Credited Amount",
    dataIndex: "creditedAmount",
    key: "creditedAmount",
  },
  {
    title: "Payment Date",
    key: "effectiveDate",
    dataIndex: "effectiveDate",
    scopedSlots: { customRender: "created" },
  },
  {
    title: "Action",
    key: "action",
    scopedSlots: { customRender: "actions" },
  },
];

export default {
  name: "Payments",
  data() {
    return {
      data: [],
      columns,
      selectedRowKeys: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 1,
      },
      params: {
        size: 10,
        page: 0,
        sort: "id,desc",
      },
      operations: 0,
      visible: false,
      redeployCandidate: {},
      errors: {},
    };
  },
  created() {
    this.getPayments();
  },
  components: {
    Navigation,
  },
  methods: {
    reDeploy(){
      this.get(
        URLS.payments + "/redeploy/" + this.redeployCandidate.uid,
        true
      ).then((res) => {
        if (!res) return;
        if (res.errors) return (this.errors = res.errors);

        this.visible = false;

        this.getPayments();
      });
    },
    onSearch(value) {
      console.log(value);
      let params = utils.copy(this.params);
      params.userUid = value;
      params.amount = value;
      params.balance = value;
      params.paymentId = value;
      params.paymentNumber = value;
      params["user.email"] = value;
      this.params = params;
      this.getPayments();
    },
    handleTableChange(pagination) {
      console.log(pagination);
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.params.size = pager.pageSize;
      this.params.page = pager.current - 1;
      this.getPayments();
    },
    async doBulkAction(action) {
      switch (action) {
        case "delete":
          for (let i in this.selectedRowKeys) {
            this.deleteCandidate = this.data.find(
              (item) => item.id === this.selectedRowKeys[i]
            );
            await this.deletePayment();
            this.deleteCandidate = {};
          }
      }
    },
    showModal(record) {
      this.redeployCandidate = record;
      this.visible = true;
    },
    formatDate(dateString) {
      return moment(dateString).format("DD-MMM-YYYY HH:mm:ss");
    },
    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    getPayments() {
      this.get(this.buildUrlWithParams(URLS.payments, this.params), true).then(
        (res) => {
          if (!res) return;
          if (res.errors) return (this.errors = res.errors);

          this.data = res.body.content;
          this.pagination.total = res.body.totalElements;
        }
      );
    },
    ...baseMethods,
  },
};
</script>
<style>
</style>