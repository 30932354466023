<template>
  <div
    class="row d-flex justify-content-center align-items-center"
    style="height: 100vh"
  >
    <div class="col-lg-6 col-11">
      <div class="text-center">
        <img
          src="../assets/images/ZimScape-02.png"
          alt=""
          class="my-4"
          style="width: 200px"
        />
      </div>
      <p class="text-center mb-4 w-75 mx-auto">
        Register by filling in the details required below
      </p>
      <a-form
        id="components-form-demo-normal-login"
        class="login-form"
        @submit="handleSubmit"
      >
        <a-divider>Customer details</a-divider>

        <div class="row">
          <div class="col-lg-6">
            <a-form-item
              :has-feedback="errors.firstName ? true : false"
              :validate-status="errors.firstName ? 'error' : ''"
              :help="errors.firstName ? errors.firstName : ''"
            >
              <a-input
                size="large"
                v-model="model.firstName"
                placeholder="First Name"
              >
                <a-icon
                  slot="prefix"
                  type="user"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input>
            </a-form-item>
          </div>
          <div class="col-lg-6">
            <a-form-item>
              <a-input
                size="large"
                v-model="model.lastName"
                type="text"
                placeholder="Last Name"
              >
                <a-icon
                  slot="prefix"
                  type="user"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input>
            </a-form-item>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <a-form-item>
              <a-input
                size="large"
                v-model="model.address"
                placeholder="Address"
              >
                <a-icon
                  slot="prefix"
                  type="home"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input>
            </a-form-item>
          </div>
          <div class="col-lg-6">
            <a-form-item
              :has-feedback="errors.phone ? true : false"
              :validate-status="errors.phone ? 'error' : ''"
              :help="errors.phone ? errors.phone : ''"
            >
              <a-input
                size="large"
                v-model="model.phone"
                type="number"
                placeholder="Phone"
              >
                <a-icon
                  slot="prefix"
                  type="phone"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input>
            </a-form-item>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <a-form-item>
              <a-input
                size="large"
                v-model="model.companyName"
                placeholder="Company Name"
              >
                <a-icon
                  slot="prefix"
                  type="bank"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input>
            </a-form-item>
          </div>
          <div class="col-lg-6">
            <a-form-item>
              <a-input
                size="large"
                v-model="model.type"
                type="text"
                placeholder="Industry"
              >
                <a-icon
                  slot="prefix"
                  type="gold"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input>
            </a-form-item>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <a-form-item>
              <a-input
                size="large"
                v-model="model.contactPerson"
                placeholder="Contact Person"
              >
                <a-icon
                  slot="prefix"
                  type="user"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input>
            </a-form-item>
          </div>
          <div class="col-lg-6">
            <a-form-item
              :has-feedback="errors.idNumber ? true : false"
              :validate-status="errors.idNumber ? 'error' : ''"
              :help="errors.idNumber ? errors.idNumber : ''"
            >
              <a-input
                size="large"
                v-model="model.idNumber"
                type="text"
                placeholder="ID Number"
              >
                <a-icon
                  slot="prefix"
                  type="key"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input>
            </a-form-item>
          </div>
        </div>

        <a-divider>Credentials</a-divider>

        <div class="row">
          <div class="col-lg-6">
            <a-form-item
              :has-feedback="errors.email ? true : false"
              :validate-status="errors.email ? 'error' : ''"
              :help="errors.email ? errors.email : ''"
            >
              <a-input
                size="large"
                v-model="model.email"
                placeholder="Email Address"
              >
                <a-icon
                  slot="prefix"
                  type="mail"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input>
            </a-form-item>
          </div>
          <div class="col-lg-6">
            <a-form-item
              :has-feedback="errors.password ? true : false"
              :validate-status="errors.password ? 'error' : ''"
              :help="errors.password ? errors.password : ''"
            >
              <a-input-password
                size="large"
                v-model="model.clearPassword"
                placeholder="Password"
              >
                <a-icon
                  slot="prefix"
                  type="lock"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input-password>
            </a-form-item>
          </div>
        </div>
        <div>
          <small v-if="errors.integrityViolation" class="text-danger">{{
            errors.integrityViolation
          }}</small>
          <small v-if="errors.messageNotReadable" class="text-danger">{{
            errors.messageNotReadable
          }}</small>
          <small v-if="errors.generalException" class="text-danger">{{
            errors.generalException
          }}</small>
          <small v-if="errors.apiException" class="text-danger">{{
            errors.apiException
          }}</small>
        </div>

        <a-form-item>
          <a-button
            type="primary"
            html-type="submit"
            class="login-form-button"
            :loading="hasOps()"
          >
            Register
          </a-button>
        </a-form-item>
        <div class="row">
          <div class="col-6">
            Or
            <router-link to="/login" class="login-form-forgot" href="">
              Back to login
            </router-link>
          </div>
          <div class="col-6 text-right"></div>
        </div>
        <Footer />
      </a-form>
    </div>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import baseMethods from "../utils/baseMethods";
import { URLS } from "@/utils/constants";

export default {
  name: "Register",
  data() {
    return {
      model: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        companyName: "",
        type: "",
        contactPerson: "",
        idNumber: "",
        address: "",
        currency: "USD",
        clearPassword: "",
      },
      errors: {},
      operations: 0,
    };
  },
  components: {
    Footer,
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();

      this.postNoAuth(
        URLS.register,
        this.model,
        "Registration successful",
        null,
        true
      ).then((res) => {
        if (!res) return;
        if (res.errors) return (this.errors = res.errors);

        this.$router.push("/login");
      });
    },
    ...baseMethods,
  },
};
</script>

<style scoped>
.ant-form-item {
  margin-bottom: 10px;
}

.ant-divider-inner-text {
  font-weight: 250 !important;
}
</style>