<template>
  <div>
    <Navigation>
      <a-page-header title="Subscriptions" class="pl-0" />
      <div class="mr-3">
        <div v-if="hasOps()" class="text-center my-3">
          <a-spin size="large" />
        </div>
        <div class="my-3 row">
          <div class="col-6">
            <a-select default-value="delete" style="min-width: 80px" class="mr-5">
              <a-select-option value="delete" @click="doBulkAction('delete')">
                Delete
              </a-select-option>
            </a-select>
            <a-input-search
              allow-clear
              placeholder="Search by Product or Domain"
              style="width: 400px"
              @search="onSearch"
            />
          </div>
          <div class="col-6 text-right">
            <a-button
              type="primary"
              :loading="hasOps()"
              @click="$router.push('/subscriptions/new')"
            >
              Create New Subscriptions
            </a-button>
          </div>
        </div>
        <a-table
          :columns="columns"
          :data-source="data"
          :pagination="pagination"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
          @change="handleTableChange"
          rowKey="id"
        >
          <span slot="name" slot-scope="text, record">
            {{ record.plan.product.prettyName }}
          </span>
          <span slot="plan" slot-scope="text, record">
            {{ record.plan.prettyName }}
          </span>
          <span slot="created" slot-scope="created">
            {{ formatDate(created) }}
          </span>
          <span slot="actions" slot-scope="text, record">
            <a-select default-value="view" style="min-width: 120px">
              <a-select-option value="view">
                <router-link :to="'/subscriptions/view/' + record.uid">
                  View
                </router-link>
              </a-select-option>
              <a-select-option
                value="edit"
                v-if="record.plan.product.name !== 'DOMAIN_NAME'"
              >
                <router-link :to="'/subscriptions/edit/' + record.uid">
                  Edit
                </router-link>
              </a-select-option>
              <a-select-option
                value="manage"
                v-if="record.plan.product.name === 'EMAIL'"
              >
                <router-link :to="'/subscriptions/manage/' + record.plan.product.label + '/' + record.uid">
                  Manage
                </router-link>
              </a-select-option>
              <a-select-option value="delete" @click="showDeleteModal(record)">
                Delete
              </a-select-option>
            </a-select>
          </span>
        </a-table>
      </div>
    </Navigation>
    <a-modal
      title="Delete Subscription"
      :visible="visible"
      :confirm-loading="hasOps()"
      @ok="deleteSubscription"
      @cancel="visible = false"
    >
      <p>
        Are you sure you want to delete {{ deleteCandidate.firstName }}
        {{ deleteCandidate.lastName }}?
      </p>
    </a-modal>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import baseMethods from "../../utils/baseMethods";
import { URLS } from "@/utils/constants";

import moment from "moment";
import { utils } from '../../utils/utils';

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "Plan",
    dataIndex: "plan",
    key: "plan",
    scopedSlots: { customRender: "plan" },
  },
  {
    title: "Price",
    dataIndex: "plan.recurringPrice",
    key: "recurringPrice"
  },
  {
    title: "Domain",
    key: "domain",
    dataIndex: "domain",
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
  },
  {
    title: "Bundle ID",
    key: "bundleId",
    dataIndex: "bundleId",
  },
  {
    title: "Created",
    key: "created",
    dataIndex: "created",
    scopedSlots: { customRender: "created" },
  },
  {
    title: "Action",
    key: "action",
    scopedSlots: { customRender: "actions" },
  },
];

export default {
  name: "Subscriptions",
  data() {
    return {
      data: [],
      columns,
      selectedRowKeys: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 1,
      },
      params: {
        size: 10,
        page: 0,
        sort: "id,desc",
      },
      operations: 0,
      visible: false,
      deleteCandidate: {},
      errors: {},
    };
  },
  created() {
    this.getSubscriptions();
  },
  components: {
    Navigation,
  },
  methods: {
    onSearch(value) {
      console.log(value);
      let params = utils.copy(this.params);
      params.userUid = value;
      params.planUid = value;
      params['plan.product.name'] = value;
      params['user.email'] = value;
      params['uid'] = value;
      params.domain = value;
      this.params = params;
      this.getSubscriptions();
    },
    handleTableChange(pagination) {
      console.log(pagination);
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.params.size = pager.pageSize;
      this.params.page = pager.current - 1;
      this.getSubscriptions();
    },
    async doBulkAction(action) {
      switch (action) {
        case "delete":
          for (let i in this.selectedRowKeys) {
            this.deleteCandidate = this.data.find(
              (item) => item.id === this.selectedRowKeys[i]
            );
            await this.deleteSubscription();
            this.deleteCandidate = {};
          }
      }
    },
    async deleteSubscription() {
      return this.delete(
        URLS.subscriptions + "/" + this.deleteCandidate.uid,
        true
      ).then((res) => {
        if (!res) return;
        if (res.errors) return (this.errors = res.errors);

        this.visible = false;

        this.getSubscriptions();
      });
    },
    showDeleteModal(record) {
      this.deleteCandidate = record;
      this.visible = true;
    },
    groupsToCommaSeparatedString(groups) {
      let string = "";
      for (var k in groups) {
        string = string + groups[k].name + ",";
      }
      return string;
    },
    formatDate(dateString) {
      return moment(dateString).format("DD-MMM-YYYY HH:mm:ss");
    },
    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    getSubscriptions() {
      this.get(
        this.buildUrlWithParams(URLS.subscriptions, this.params),
        true
      ).then((res) => {
        if (!res) return;
        if (res.errors) return (this.errors = res.errors);

        this.data = res.body.content;
        this.pagination.total = res.body.totalElements;
      });
    },
    ...baseMethods,
  },
};
</script>
<style>
</style>